import Vue from 'vue'
import Router from 'vue-router'
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(Router)


var ua = navigator.userAgent;
var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
isIphone =!ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
isAndroid = ua.match(/(Android)\s+([\d.]+)/),
isMobile = isIphone || isAndroid;

//判断
let router = ""
if(isMobile){
   router = new Router({
    routes: [
      {
        path: '/',
        name: 'mobileindex',
        component: () => import('./components/mobile/index.vue'),
      },
      {
        path: '/components/mobile/aboutus',
        name: 'mobileaboutus',
        component: () => import('./components/mobile/aboutus.vue'),
      },
      {
        path: '/components/mobile/itc',
        name: 'itc',
        component: () => import('./components/mobile/itc.vue'),
      },
      {
        path: '/components/mobile/ict-1',
        name: 'ict-1',
        component: () => import('./components/mobile/ict-1.vue'),
      },
      {
        path: '/components/mobile/ict-2',
        name: 'ict-2',
        component: () => import('./components/mobile/ict-2.vue'),
      },
      {
        path: '/components/mobile/ict-3',
        name: 'ict-3',
        component: () => import('./components/mobile/ict-3.vue'),
      },
      {
        path: '/components/mobile/ict-4',
        name: 'ict-4',
        component: () => import('./components/mobile/ict-4.vue'),
      },
      {
        path: '/components/mobile/ict-5',
        name: 'ict-5',
        component: () => import('./components/mobile/ict-5.vue'),
      },
      {
        path: '/components/mobile/ict-6',
        name: 'ict-6',
        component: () => import('./components/mobile/ict-6.vue'),
      },
      {
        path: '/components/mobile/ict-7',
        name: 'ict-7',
        component: () => import('./components/mobile/ict-7.vue'),
      },
      {
        path: '/components/mobile/ict-8',
        name: 'ict-8',
        component: () => import('./components/mobile/ict-8.vue'),
      },
      {
        path: '/components/mobile/jionus',
        name: 'jionus',
        component: () => import('./components/mobile/jionus.vue'),
      },
      {
        path: '/components/mobile/contactus',
        name: 'contactus',
        component: () => import('./components/mobile/contactus.vue'),
      },
      {
        path: '/components/mobile/chanpin',
        name: 'chanpin',
        component: () => import('./components/mobile/chanpin.vue'),
      },
      {
        path: '/components/mobile/chanpin-1',
        name: 'chanpin-1',
        component: () => import('./components/mobile/chanpin-1.vue'),
      },
      {
        path: '/components/mobile/chanpin-2',
        name: 'chanpin-2',
        component: () => import('./components/mobile/chanpin-2.vue'),
      },
      {
        path: '/components/mobile/chanpin-3',
        name: 'chanpin-3',
        component: () => import('./components/mobile/chanpin-3.vue'),
      },
      {
        path: '/components/mobile/chanpin-4',
        name: 'chanpin-4',
        component: () => import('./components/mobile/chanpin-4.vue'),
      },
      {
        path: '/components/mobile/chanpin-5',
        name: 'chanpin-5',
        component: () => import('./components/mobile/chanpin-5.vue'),
      },
      {
        path: '/components/mobile/chanpin-6',
        name: 'chanpin-6',
        component: () => import('./components/mobile/chanpin-6.vue'),
      },
      {
        path: '/components/mobile/chanpin-7',
        name: 'chanpin-7',
        component: () => import('./components/mobile/chanpin-7.vue'),
      },
      {
        path: '/components/mobile/chanpin-8',
        name: 'chanpin-8',
        component: () => import('./components/mobile/chanpin-8.vue'),
      },
      {
        path: '/components/mobile/chanpin-9',
        name: 'chanpin-9',
        component: () => import('./components/mobile/chanpin-9.vue'),
      },
      {
        path: '/components/mobile/chanpin-10',
        name: 'chanpin-10',
        component: () => import('./components/mobile/chanpin-10.vue'),
      },
      {
        path: '/components/mobile/chanpin-11',
        name: 'chanpin-11',
        component: () => import('./components/mobile/chanpin-11.vue'),
      },
      {
        path: '/components/mobile/chanpin-12',
        name: 'chanpin-12',
        component: () => import('./components/mobile/chanpin-12.vue'),
      },
      {
        path: '/components/mobile/chanpin-13',
        name: 'chanpin-13',
        component: () => import('./components/mobile/chanpin-13.vue'),
      },
      {
        path: '/components/mobile/chanpin-14',
        name: 'chanpin-14',
        component: () => import('./components/mobile/chanpin-14.vue'),
      },
      {
        path: '/components/mobile/chanpin-15',
        name: 'chanpin-15',
        component: () => import('./components/mobile/chanpin-15.vue'),
      },
      {
        path: '/components/mobile/chanpin-16',
        name: 'chanpin-16',
        component: () => import('./components/mobile/chanpin-16.vue'),
      },
      {
        path: '/components/mobile/chanpin-17',
        name: 'chanpin-17',
        component: () => import('./components/mobile/chanpin-17.vue'),
      },
      {
        path: '/components/mobile/chanpin-18',
        name: 'chanpin-18',
        component: () => import('./components/mobile/chanpin-18.vue'),
      },


    ]
  })
}else{
  router = new Router({
    routes: [
      {
        path: '/',
        name: 'webindex',
        component: () => import('./components/web/index.vue'),
      },
      {
        path: '/components/web/aboutus/index',
        name: 'aboutus',
        component: () => import('./components/web/aboutus/index.vue'),
      },
      {
        path: '/components/web/aboutus/corporateVision',
        name: 'corporateVision',
        component: () => import('./components/web/aboutus/corporateVision.vue'),
      },
      {
        path: '/components/web/solutions/index',
        name: 'solutions',
        component: () => import('./components/web/solutions/index.vue'),
      },     
      {
        path: '/components/web/solutions/case01',
        name: 'case01',
        component: () => import('./components/web/solutions/case01.vue'),
      },
      {
        path: '/components/web/solutions/case01-1',
        name: 'case01-1',
        component: () => import('./components/web/solutions/case01-1.vue'),
      },
      {
        path: '/components/web/solutions/case01-5',
        name: 'case01-5',
        component: () => import('./components/web/solutions/case01-5.vue'),
      },
      {
        path: '/components/web/solutions/case01-6',
        name: 'case01-6',
        component: () => import('./components/web/solutions/case01-6.vue'),
      },
      {
        path: '/components/web/solutions/case01-2',
        name: 'case01-2',
        component: () => import('./components/web/solutions/case01-2.vue'),
      },
      {
        path: '/components/web/solutions/case01-3',
        name: 'case01-2',
        component: () => import('./components/web/solutions/case01-3.vue'),
      },
      {
        path: '/components/web/solutions/case01-4',
        name: 'case01-2',
        component: () => import('./components/web/solutions/case01-4.vue'),
      },
      {
        path: '/components/web/solutions/case02',
        name: 'case02',
        component: () => import('./components/web/solutions/case02.vue'),
      },
      {
        path: '/components/web/solutions/case02-1',
        name: 'case02-1',
        component: () => import('./components/web/solutions/case02-1.vue'),
      },
      {
        path: '/components/web/solutions/case02-2',
        name: 'case02-2',
        component: () => import('./components/web/solutions/case02-2.vue'),
      },
      {
        path: '/components/web/solutions/case02-3',
        name: 'case02-3',
        component: () => import('./components/web/solutions/case02-3.vue'),
      },
      {
        path: '/components/web/solutions/case02-4',
        name: 'case02-4',
        component: () => import('./components/web/solutions/case02-4.vue'),
      },
      {
        path: '/components/web/solutions/case02-5',
        name: 'case02-5',
        component: () => import('./components/web/solutions/case02-5.vue'),
      },
      {
        path: '/components/web/solutions/case02-6',
        name: 'case02-6',
        component: () => import('./components/web/solutions/case02-6.vue'),
      },
      {
        path: '/components/web/solutions/case03',
        name: 'case03',
        component: () => import('./components/web/solutions/case03.vue'),
      },
      {
        path: '/components/web/solutions/case03-1',
        name: 'case03-1',
        component: () => import('./components/web/solutions/case03-1.vue'),
      },
      {
        path: '/components/web/solutions/case03-2',
        name: 'case03-2',
        component: () => import('./components/web/solutions/case03-2.vue'),
      },
      {
        path: '/components/web/solutions/case03-3',
        name: 'case03-3',
        component: () => import('./components/web/solutions/case03-3.vue'),
      },
      {
        path: '/components/web/solutions/case04',
        name: 'case04',
        component: () => import('./components/web/solutions/case04.vue'),
      },
      {
        path: '/components/web/solutions/case04-1',
        name: 'case04-1',
        component: () => import('./components/web/solutions/case04-1.vue'),
      },
      {
        path: '/components/web/solutions/case04-2',
        name: 'case04-2',
        component: () => import('./components/web/solutions/case04-2.vue'),
      },
      {
        path: '/components/web/solutions/case04-3',
        name: 'case04-3',
        component: () => import('./components/web/solutions/case04-3.vue'),
      },
      {
        path: '/components/web/solutions/case05',
        name: 'case04',
        component: () => import('./components/web/solutions/case05.vue'),
      },
      {
        path: '/components/web/itc/index',
        name: 'itc',
        component: () => import('./components/web/itc/index.vue'),
      },
      {
        path: '/components/web/itc/case01',
        name: 'case01',
        component: () => import('./components/web/itc/case01.vue'),
      },
      {
        path: '/components/web/itc/case01-1',
        name: 'case01-1',
        component: () => import('./components/web/itc/case01-1.vue'),
      },
      {
        path: '/components/web/itc/case01-2',
        name: 'case01-2',
        component: () => import('./components/web/itc/case01-2.vue'),
      },
      {
        path: '/components/web/itc/case01-3',
        name: 'case01-3',
        component: () => import('./components/web/itc/case01-3.vue'),
      },
      {
        path: '/components/web/itc/case01-4',
        name: 'case01-4',
        component: () => import('./components/web/itc/case01-4.vue'),
      },
      {
        path: '/components/web/itc/case01-5',
        name: 'case01-5',
        component: () => import('./components/web/itc/case01-5.vue'),
      },
      {
        path: '/components/web/itc/case01-6',
        name: 'case01-6',
        component: () => import('./components/web/itc/case01-6.vue'),
      },
      {
        path: '/components/web/itc/case01-7',
        name: 'case01-7',
        component: () => import('./components/web/itc/case01-7.vue'),
      },
      {
        path: '/components/web/itc/case02',
        name: 'case02',
        component: () => import('./components/web/itc/case02.vue'),
      },
      {
        path: '/components/web/itc/case03',
        name: 'case03',
        component: () => import('./components/web/itc/case03.vue'),
      },
      {
        path: '/components/web/news/index',
        name: 'news',
        component: () => import('./components/web/news/index.vue'),
      },
      {
        path: '/components/web/news/case01',
        name: 'case01',
        component: () => import('./components/web/news/case01.vue'),
      },
      {
        path: '/components/web/news/case01-1',
        name: 'case01-1',
        component: () => import('./components/web/news/case01-1.vue'),
      },
      {
        path: '/components/web/news/case01-2',
        name: 'case01-2',
        component: () => import('./components/web/news/case01-2.vue'),
      },
      {
        path: '/components/web/joinus/index',
        name: 'joinus',
        component: () => import('./components/web/joinus/index.vue'),
      },
      {
        path: '/components/web/joinus/case01',
        name: 'case01',
        component: () => import('./components/web/joinus/case01.vue'),
      },
      {
        path: '/components/web/joinus/case02',
        name: 'case02',
        component: () => import('./components/web/joinus/case02.vue'),
      },
      {
        path: '/components/web/joinus/case03',
        name: 'case03',
        component: () => import('./components/web/joinus/case03.vue'),
      },
      {
        path: '/components/web/joinus/case04',
        name: 'case04',
        component: () => import('./components/web/joinus/case04.vue'),
      },
      {
        path: '/components/web/aboutus/qyyj',
        name: 'qyyj',
        component: () => import('./components/web/aboutus/qyyj.vue'),
      },
      {
        path: '/components/web/aboutus/zyzz',
        name: 'zyzz',
        component: () => import('./components/web/aboutus/zyzz.vue'),
      },
    ]
  })
}


router.beforeEach((to, from, next) => {
  // console.log(to.path)
  if(to.path == "/"){ 
    localStorage.setItem("activeIndex",0)
    next()
  }else{
    next()
  }
})

export default router
