import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
//中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale:localStorage.getItem('languageSet')||'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        'zh':require('./assets/language/zh'),
        'en':require('./assets/language/en')
    }
})
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueBus from 'vue-bus';
Vue.use(VueBus);
import { Button,Sticky,Cell, CellGroup,Icon,Swipe, SwipeItem,Lazyload } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Sticky);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Lazyload);
import { Image as VanImage } from 'vant';
Vue.use(VanImage);
new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
