<template>
  <div id="app">
    <div v-if="showWeb">
      <el-backtop></el-backtop>
      <div style="position: relative;">
        <div :class="{ backgroundSty: fixed }">
          <div
            class="yellow"
            :class="{ fixed: fixed }"
            style="width:1200px;margin: 0 auto; position: absolute; top:0; left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);z-index: 20;"
          >
            <div style="float:left;margin-top: 14px; margin-left:20px">
              <el-image
                fit="fill"
                style="width: 166px; height: 35px"
                :src="logoUrl"
              ></el-image>
            </div>
            <div style="float:right">
              <mainNav ref="mainNav" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showWeb" class="mobileMian">
      <van-sticky>
        <div class="mobileNav">
          <el-image
            fit="fill"
            style="width: 166px; height: 35px;float:left;margin-left:1rem;margin-top:1.1rem "
            :src="logoUrl"
          ></el-image>
          <!-- <img
            :src="mLogo"
            alt=""
            style="float:left; height:35px; margin-left:1rem;margin-top:1.2rem"
          /> -->

          <el-image
            style="float:right;width:1.5rem; height: 1.5rem;margin-right:1rem;margin-top:1.5rem"
            :src="navBtn"
            fit="contain"
            @click="table = true"
          ></el-image>
        </div>
        <el-drawer
          :visible.sync="table"
          direction="ltr"
          size="80%"
          style="text-align:left;"
        >
          <div style="margin-left:3rem">
            <van-cell
              :title="$t('Nav.home')"
              style="margin-bottom:1rem"
              @click="goPath(1)"
            />
            <van-cell
              :title="$t('Nav.abus')"
              style="margin-bottom:1rem"
              @click="goPath(2)"
            />
            <van-cell
              :title="$t('Nav.cpfa')"
              style="margin-bottom:1rem"
              @click="goPath(6)"
            />
            <van-cell
              :title="$t('Nav.itc')"
              style="margin-bottom:1rem"
              @click="goPath(3)"
            />
            <van-cell
              :title="$t('Nav.joinus')"
              style="margin-bottom:1rem"
              @click="goPath(4)"
            />
            <van-cell
              :title="$t('Nav.lianxiwomen')"
              style="margin-bottom:1rem"
              @click="goPath(5)"
            />
            <van-cell
              :title="$t('Nav.yuyan')"
              style="margin-bottom:1rem"
              @click="changeLanguage"
            />
          </div>
        </el-drawer>
      </van-sticky>
    </div>
    <transition mode="out-in" name="fade-transform">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
    <div v-if="!showWeb" class="mobileMian">
      <div class="footerSty">
        <a href="http://beian.miit.gov.cn" style="color:#666666">
          {{ $t('Nav.banquan') }}</a
        >
      </div>
    </div>

    <div v-if="showWeb">
      <div class="footer" v-if="this.$i18n.locale == 'zh'">
        <div style="width:1400px;margin:0 auto">
          <div style="padding-top:50px; text-align:left!important">
            <div style="margin-bottom:5px">
              <router-link :to="{ path: '/components/web/aboutus/index' }">
                <el-link class="linkText">
                  {{ $t('Nav.abus') }}</el-link
                ></router-link
              >
              <router-link :to="{ path: '/components/web/solutions/index' }">
                <el-link class="linkText">{{ $t('Nav.cpfa') }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/index' }">
                <el-link class="linkText"> {{ $t('Nav.itc') }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/index' }">
                <el-link class="linkText"> {{ $t('Nav.joinus') }}</el-link>
              </router-link>
              <el-link class="linkText">{{ $t('Nav.lianxiwomen') }} </el-link>
            </div>
            <div>
              <router-link
                :to="{ path: '/components/web/aboutus/corporateVision' }"
              >
                <el-link class="linkText2" style="margin-right:124px">
                  {{ $t('Nav.qiyegaishu') }}</el-link
                ></router-link
              >
              <router-link :to="{ path: '/components/web/solutions/case01' }">
                <el-link class="linkText2" style="margin-right:156px">{{
                  $t('Nav.qiyezonghehuaguanli')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case01' }">
                <el-link class="linkText2" style="margin-right:198px">{{
                  $t('Nav.quanfangweiitc')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case01' }">
                <el-link class="linkText2" style="margin-right:124px">{{
                  $t('Nav.zhaopin')
                }}</el-link>
              </router-link>

              <el-link class="linkText2" style="margin-right:100px">
                {{ $t('Nav.danwei') }}</el-link
              >
            </div>
            <div>
              <router-link :to="{ path: '/components/web/aboutus/qyyj' }">
                <el-link class="linkText2" style="margin-right:124px">
                  {{ $t('Nav.qiyeyuanjing') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/solutions/case02' }">
                <el-link class="linkText2" style="margin-right:156px">{{
                  $t('Nav.nengyuanshihuaguanli')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case02' }">
                <el-link class="linkText2" style="margin-right:186px">{{
                  $t('Nav.quanqiuopo')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case02' }">
                <el-link class="linkText2" style="margin-right:110px">{{
                  $t('Nav.peixunyufazhan')
                }}</el-link>
              </router-link>

              <el-link class="linkText2" style="margin-right:100px">
                {{ $t('Nav.dizhi') }}
              </el-link>
            </div>
            <div>
              <router-link :to="{ path: '/components/web/aboutus/zyzz' }">
                <el-link class="linkText2" style="margin-right:124px">
                  {{ $t('Nav.zhuanyezizhi') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/solutions/case03' }">
                <el-link class="linkText2" style="margin-right:170px">
                  {{ $t('Nav.guojiyunying') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case03' }">
                <el-link class="linkText2" style="margin-right:236px">{{
                  $t('Nav.anlifenxiang')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case03' }">
                <el-link class="linkText2" style="margin-right:110px">{{
                  $t('Nav.shenghuoyufuli')
                }}</el-link>
              </router-link>
              <el-link class="linkText2" style="margin-right:100px"
                >{{ $t('Nav.cairun') }} <br />
                {{ $t('Nav.dizhi2') }}
              </el-link>
            </div>
            <div>
              <router-link :to="{ path: '/components/web/joinus/case04' }">
                <el-link class="linkText2" style="margin-right:124px;">{{
                  $t('Nav.qiyewenhua')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/solutions/case04' }">
                <el-link class="linkText2" style="margin-right:433px;">{{
                  $t('Nav.dashuju')
                }}</el-link>
              </router-link>

              <el-link class="linkText2" style="margin-right:69px;">
                {{ $t('Nav.dianhua') }}
              </el-link>
              <el-link class="linkText2">
                {{ $t('Nav.dizhi2_1') }}
              </el-link>
            </div>
            <div>
              <el-link class="linkText2" style="margin-left:650px;">
                {{ $t('Nav.youjian') }}：gl_hresource @126.com
                <span style="margin-left:34px;"> {{ $t('Nav.dizhi3') }}</span>
              </el-link>
            </div>
          </div>
          <div style="color:#ffffff; margin-top:46px;">
            <a href="http://beian.miit.gov.cn" style="color:#ffffff; ">
              {{ $t('Nav.banquan') }}</a
            >
          </div>
        </div>
      </div>

      <div class="footer" v-if="this.$i18n.locale == 'en'">
        <div style="width:1300px;margin:0 auto">
          <div style="padding-top:50px; text-align:left!important">
            <div style="margin-bottom:5px">
              <router-link :to="{ path: '/components/web/aboutus/index' }">
                <span class="linkTextEn">
                  {{ $t('Nav.abus') }}</span
                ></router-link
              >

              <router-link :to="{ path: '/components/web/solutions/index' }">
                <el-link class="linkTextEn">{{ $t('Nav.cpfa') }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/index' }">
                <el-link class="linkTextEn"> {{ $t('Nav.itc') }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/index' }">
                <el-link class="linkTextEn"> {{ $t('Nav.joinus') }}</el-link>
              </router-link>

              <el-link class="linkTextEn">{{ $t('Nav.lianxiwomen') }} </el-link>
            </div>
            <div>
              <router-link
                :to="{ path: '/components/web/aboutus/corporateVision' }"
              >
                <el-link class="linkText2" style="margin-right:25px">{{
                  $t('Nav.qiyegaishu')
                }}</el-link></router-link
              >
              <router-link :to="{ path: '/components/web/solutions/case01' }">
                <el-link class="linkText2" style="margin-right:14px">{{
                  $t('Nav.qiyezonghehuaguanli')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case01' }">
                <el-link class="linkText2" style="margin-right:100px">{{
                  $t('Nav.quanfangweiitc')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case01' }">
                <el-link class="linkText2" style="margin-right:110px">{{
                  $t('Nav.zhaopin')
                }}</el-link>
              </router-link>
              <el-link class="linkText2" style="margin-right:25px">
                {{ $t('Nav.danwei') }}</el-link
              >
            </div>
            <div>
              <router-link :to="{ path: '/components/web/aboutus/qyyj' }">
                <el-link class="linkText2" style="margin-right:15px">
                  {{ $t('Nav.qiyeyuanjing') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/solutions/case02' }">
                <el-link class="linkText2" style="margin-right:10px">{{
                  $t('Nav.nengyuanshihuaguanli')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case02' }">
                <el-link class="linkText2" style="margin-right:2px">{{
                  $t('Nav.quanqiuopo')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case02' }">
                <el-link class="linkText2" style="margin-right:2px">{{
                  $t('Nav.peixunyufazhan')
                }}</el-link>
              </router-link>
              <el-link class="linkText2" style="float:right">
                {{ $t('Nav.dizhi') }} <br />
                {{ $t('Nav.dizhi_1') }}
              </el-link>
            </div>
            <div>
              <router-link :to="{ path: '/components/web/aboutus/zyzz' }">
                <el-link class="linkText2" style="margin-right:24px">
                  {{ $t('Nav.fazhanlicheng') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/solutions/case03' }">
                <el-link class="linkText2" style="margin-right:65px">
                  {{ $t('Nav.guojiyunying') }}</el-link
                >
              </router-link>
              <router-link :to="{ path: '/components/web/itc/case03' }">
                <el-link class="linkText2" style="margin-right:103px">{{
                  $t('Nav.anlifenxiang')
                }}</el-link>
              </router-link>
              <router-link :to="{ path: '/components/web/joinus/case03' }">
                <el-link class="linkText2" style="margin-right:103px">{{
                  $t('Nav.shenghuoyufuli')
                }}</el-link>
              </router-link>
              <el-link class="linkText2" style="float: right">{{
                $t('Nav.cairun')
              }}</el-link>
            </div>
            <div>
              <router-link :to="{ path: '/components/web/joinus/case04' }">
                <el-link class="linkText2" style="margin-right:126px;">{{
                  $t('Nav.qiyewenhua')
                }}</el-link>
              </router-link>
              <el-link class="linkText2" style="margin-right:500px;">{{
                $t('Nav.dashuju')
              }}</el-link>

              <el-link class="linkText2">
                {{ $t('Nav.dianhua') }}
              </el-link>
              <el-link class="linkText2" style="float:right">
                {{ $t('Nav.dizhi2') }} <br />
                {{ $t('Nav.dizhi2_2') }}
              </el-link>
            </div>
            <div>
              <el-link class="linkText2" style="margin-left:700px;">
                {{ $t('Nav.youjian') }}：gl_hresource@126.com
              </el-link>
              <el-link class="linkText2" style="float:right">
                {{ $t('Nav.dizhi3') }}
              </el-link>
            </div>
          </div>
          <div style="margin-top:40px;">
            <a href="http://beian.miit.gov.cn" style="color:#ffffff; ">
              {{ $t('Nav.banquan') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainNav from './components/web/nav'
import router from './router'
export default {
  name: 'app',
  components: {
    mainNav,
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 32) {
        return value.slice(0, 32) + '...'
      }
      return value
    },
  },
  data() {
    return {
      table: false,
      activeName: '',
      showLoading: true,
      fixed: false,
      showWeb: true,
      logoUrl: require('./assets/logo-w.png'),
      mLogo: require('./assets/logo-w.png'),
      navBtn: require('./assets/mobile/navBtn.png'),
    }
  },
  mounted() {
    this.showLoading = false
    window.addEventListener('scroll', this.handleScroll)
    if (localStorage.getItem('activeIndex') == 0) {
      this.$bus.$emit('getCurNav', 'home')
    }
    if (this._isMobile()) {
      this.showWeb = false
    } else {
      this.showWeb = true
    }
  },
  methods: {
    goPath(num) {
      if (num == 1) {
        router.push('/')
      } else if (num == 2) {
        router.push('/components/mobile/aboutus')
      } else if (num == 3) {
        router.push('/components/mobile/itc')
      } else if (num == 4) {
        router.push('/components/mobile/jionus')
      } else if (num == 5) {
        router.push('/components/mobile/contactus')
      } else {
        router.push('/components/mobile/chanpin')
      }

      this.table = false
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      // console.log(flag)
      return flag
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let offsetTop = document.querySelector('.yellow').offsetTop
      if (scrollTop > offsetTop) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    changeLanguage() {
      this.$i18n.locale == 'zh'
        ? (this.$i18n.locale = 'en')
        : (this.$i18n.locale = 'zh') //设置中英文模式
      localStorage.setItem('languageSet', this.$i18n.locale) //将用户设置存储到localStorage以便用户下次打开时使用此设置
    },
  },
}
</script>

<style>
.mobileNav {
  width: 100%;
  height: 70px;
  background: #101751;
}
ul {
  list-style: none;
}
.linkText {
  height: 19px;
  font-size: 20px !important;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #a0aaff !important;
  line-height: 40px;
  margin-right: 100px;
}
.linkTextEn {
  height: 19px;
  font-size: 20px !important;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #a0aaff !important;
  line-height: 40px;
  margin-right: 165px;
}

.linkText2 {
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 32px;
  display: inline-block;
  margin-top: 3px;
}
.footer {
  width: 100%;
  height: 380px;
  background: #2c3dd6;
}
.fixed {
  background: #333333;
  position: fixed !important;
  left: 50%; /* 定位父级的50% */
  top: 50%;
  transform: translate(-50%, -50%); /*自己的50% */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
.loading {
  position: absolute;
  left: 50%; /* 定位父级的50% */
  top: 50%;
  transform: translate(-50%, -50%); /*自己的50% */
}
.app-minHeight {
  min-height: calc(100vh - 130px);
}
.el-menu--horizontal > .el-menu-item.is-active {
  background-color: #475aff !important;
  border-bottom: none !important;
}
.el-menu.el-menu--horizontal {
  border: none !important;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #475aff !important;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #475aff !important;
}
.el-submenu__title i {
  display: none;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-color: #475aff !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}
.backgroundSty {
  background: #333333;
  position: fixed !important;
  z-index: 9;
  width: 100%;
  height: 60px;
}
a {
  text-decoration: none !important;
}
.el-drawer {
  background: #101751 !important;
}
.mobileMian .el-dialog__close {
  color: #ffffff !important;
}
.mobileMian .van-cell__title {
  color: #ffffff !important;
}
.mobileMian .footerSty {
  font-size: 14px;
}
/*包含以下四种的链接*/
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
</style>
