module.exports = {
  language: {
      name: 'Zh'
  },
  title:"golead",
  Nav:{
    home:"HOME",
    abus:"ABOUT US",
    cpfa:"SOLUTIONS",
    itc:"ICT",
    news:"NEWS",
    joinus:"JOIN US",
    qiyegaishu:"Enterprise Overview",
    qiyeyuanjing:"Corporate Vision",
    zhuanyezizhi:"Professional Qualification",
    qiyezonghehuaguanli:"Comprehensive Management of Enterprises",
    nengyuanshihuaguanli:"Energy and Petrochemical Business Management",
    guojiyunying:"Link Optimization of International Operators",
    dashuju:"Big Data Application Platform",
    xitongjishuyuzhichi:"System Technical Operation and Maintenance and Support",
    quanfangweiitc:"Full Range of IDC Services",
    quanqiuopo:"Global Pop Point Construction",
    anlifenxiang:"Case Sharing",
    zhaopin:"Recruit",
    peixunyufazhan:"Training and Development",
    shenghuoyufuli:"Life and Welfare",
    lianxiwomen:"Contact Us",
    danwei:"Setting: Guangzhou Guoli Computer Co., Ltd",
    dizhi:"Address: No.2 Dongguan village, Tianhe District, ",
    dizhi_1:"Guangzhou Cairun International Building 3025-3029",
    dizhi2:"21/33 Tai Lin Pai Road, Vanta Industrial Centre, ",
    dizhi2_2:" 8/F Unit18 Kwai Chung, Hong Kong",
    dizhi3:"546, 5F, Xintang building, 223 Chashan Road, Central District, Seoul, Korea",
    fazhanlicheng:"Development History",
    cairun:"3 Ang Mo Kio Street 62, #07-05 Link@AMK Singapore",
    dianhua:"Telephone：020-87294662",
    banquan:"©2011-2021 Guangzhou Guoli Computer Technology Co., Ltd. All Rights Reserved",
    yuyan:"switchLanguage：Zh",
    youjian:"email",
    hezuogongsi:"Cooperative Company",
    qiyewenhua:"Corporate Culture",
    indexText01:"Management is to deal with human relations",
    indexText02:"Build Sinopec's energy management system and improve the refined level of energy management",
    indexText03:"Link aggregation technology",
    indexText04:"Big data, another technological change in IT industry",
    indexImgText01:"Computer room infrastructure / generic cabling / equipment and integrated system delivery",
    indexImgText02:"Dynamic environment and video monitoring / equipment inspection and emergency treatment / data center operation management and maintenance",
    indexImgText03:"Covering Asia, Europe, North America",
    indexImgText04:"China Mobile international roaming user Google Map Service",
    indexImgText05:"Wiring maintenance of China Mobile International's Tseung Kwan O computer room",
    qiyegaishuText01:"Guoli technology is a private high-tech enterprise engaged in software and information product development, system integration and information service. Guoli technology's main business includes: software development, system integration and technical services, aiming to provide users with comprehensive IT solutions.",
    qiyegaishuText02:"Constantly improve the staff's material and spiritual fullness, and contribute to social development.",
    qiyegaishuText03:"ISO 9001 quality management system certification, ISO 27001 Information security management system certification, ITSS information technology service standard compliance certificate, Guangzhou software enterprise certification certificate, high-tech enterprise certificate, with more than 30 software copyright certificates",
    qiyegaishuText04:"Golead Technology Co., Ltd. (hereinafter referred to as Guoli Technology) is a private high-tech enterprise engaged in software and information product development, system integration and information services. Guoli technology's main business includes: software development, system integration and technical services, aiming to provide users with comprehensive IT solutions.",
    qiyegaishuText05:"Golead Technology Co., Ltd, located in Guangzhou, Guangdong Province, was established in 2011. The company has a registered capital of 5 million yuan, an annual income of 30 million yuan from software and services, and more than 60 employees. Company departments: R & D department, marketing department, operation and maintenance department, finance department, Henan business department, Hunan business department, Hainan business department.",
    qiyegaishuText06:"Guoli technology is in line with the trend of 'Internet plus', actively exploring the technology field of mobile Internet, and has already been involved in many fields, such as e-commerce application, Internet finance, O2O business system and self media marketing. It involves apparel industry, financial industry, advertising industry and so on.",
    qiyegaishuText07:"Guoli technology has introduced international best practices and methods, combined with years of practical operation and maintenance management experience of industry data center, formed a set of mature IT operation and maintenance service system and specifications, based on ITIL process framework, realized the integration of IT management and business services, and provided high-quality IT infrastructure operation and management services for many central enterprises.",
    qiyegaishuText08:"Love and respect Simple, focused, diligent and practical",
    qiyeyuanjingText01:"Corporate mission",
    qiyeyuanjingText02:"Benefit mankind with science and technology and win respect with achievements",
    qiyeyuanjingText03:"Enterprise spirit",
    qiyeyuanjingText04:"Benefit mankind with science and technology and win respect with achievements",
    qiyeyuanjingText05:"Enterprise tenet",
    qiyeyuanjingText06:"Be responsible for customers, care for employees, return to shareholders and contribute to society",
    guolizizhi:"Golead Qualifications",
    zizhi01:"quality management system certification",
    zizhi02:"Information security management system certification",
    zizhi03:"Certification of information technology service management system",
    zizhi04:"Software enterprise certificate",
    zizhi05:"High tech enterprise certificate",
    zizhi06:"Software copyright certificate",
    qiyewenhua02:"Team refers to a small group of people with common goals and different abilities consciously coordinate behavior or force system. This group of people, just like the facial features of the same person, work together to maintain a person's survival.",
    qiyewenhua03:"We are a professional team. Our members have many years of professional and technical background in information security, and they come from the front-line backbone of domestic well-known security companies.",
    qiyewenhua04:"We are a young team. Our average age is only 26 years old, full of vigor and innovation.",
    qiyewenhua05:"We are a dedicated team. We firmly believe that a safe brand comes from the trust of customers. Only by focusing can we do a good job in safety.",
    qiyewenhua07:"We are a team with dreams. We come from all over the world, because of a common dream: to be a truly excellent information security enterprise, to provide customers with the most reliable internet security protection.",
    qiyewenhua01:"Professional, young, with dream and strong action",
    qxfz01:"Industry communication",
    qxfz02:"Course training",
    qxfz03:"Staff induction training",
    qxfz04:"Book sharing",
    qxfz05:"Skills training",
    qxfz06:"salon",
    qxfz07:"Provide induction training for new employees and learning opportunities for old employees",
    jinyibuliaojie:"Learn more",
    shfl01:"Employee welfare",
    shfl02:"Employees are valuable assets of the company",
    qxfz08:"Social security includes pension, work-related injury, childbirth, unemployment and medical insurance, which is handled according to the local policy of the place where the employee belongs. Holidays: legal holidays, marriage leave, funeral leave, maternity leave, annual leave and sick leave.",
    sdhujuzhongxin:"DATA CENTER MANAGEMENT SYSTEM",
    sdhujuzhongxin01:"Integration and reorganization of information resources",
    sdhujuzhongxin02:"Multi level and multi latitude information system directory system",
    sdhujuzhongxin03:"Multi view display of operation and maintenance status",
    case01_1:"Product features",
    case01_2:"Perfect and reliable big data acquisition system adopts open and universal design, supports data acquisition of mainstream database platform, multiple data carrier formats and data structures, excellent data acquisition performance, rich function conversion algorithm library, improves data preprocessing efficiency, and realizes 'one-stop' ETL process management with drag and drop visual configuration interface· High performance data service platform· SOA Service Framework realizes real time data sharing service· Data push & Pull Technology· ' The end-to-end data exchange service has multiple strategies to ensure the authority of information resources, source control strategy, authoritative sequence strategy, post update sequence strategy and conflict audit strategy",
    case01_3:"Integration and reorganization of information resources",
    case01_4:"The integration and reorganization of information resources is a state of existence of optimal combination of information resources. According to the specific needs, the discrete, pluralistic, heterogeneous and distributed information resources are clustered and reorganized to form a new organic whole, forming a new information resource system with better efficiency and higher efficiency, and adapting to the user centered personalized information service mode.",
    case01_5:"Multi level and multi dimensional information resources catalogue system",
    case01_6:"Information resource catalog system is the window of government information resource service. Smart@Data Metadata technology is used to describe the characteristics of information resources to form a unified and standardized directory content. Through the effective organization and management of the directory content, the information sharing mode of physical dispersion and logical concentration of information resources is formed to provide the discovery and positioning service of information resources.",
    case01_7:"Multi view display of operation and maintenance status",
    case01_8:"The operation and maintenance management cockpit with dashboard and KPI technology can accurately understand the overall operation of the data center. The operation and maintenance log with event as the core supports real-time fault notification of voice, email and SMS.",
    case01_9:"Application of data center management system independently developed by Guoli Technology We will gradually establish a big data formation mechanism for the interaction between the government and the society. It aims to open the boundaries between government departments and between the government and the public, eliminate the phenomenon of information island, promote data exchange and sharing, fully explore the additional economic value of information, and improve the government's social governance ability and public service ability. In today's big data era, the scale of data is also growing rapidly. Data has penetrated into all walks of life and become an important production factor. Data evolution reflects huge commercial value and social value, which is directly related to the improvement of productivity. With the rapid development of E-government in recent years, various government departments have accumulated a large amount of government information, but the fragmentation system makes the emergence of information island inevitable. The government data center based on smart @ data has three functions.",
    case02_1:"SCIENTIFIC RESEARCH MANAGEMENT DECISION SUPPORT SYSTEM",
    case02_2:"Scientific research management is an important part of integrating scientific and technological innovation and promoting the application of new technologies. It plays an irreplaceable role in promoting the combination of scientific research and economy, realizing industrial upgrading and economic restructuring. Scientific research management decision support system is a set of management decision system suitable for thousands of scientific research enterprises launched by Guoli science and technology on BL platform with independent intellectual property rights. It is an important guarantee to coordinate the relationship between all parties of scientific research management, make effective use of human, financial, material and other scientific research resources, and straighten out the relationship between various elements in scientific research activities.",
    case02_3:"System introduction",
    case02_4:"Smart@Business It is an enterprise level business intelligence application platform, which has accumulated many years of business intelligence best practice experience and integrated the data analysis and decision support needs of scientific research industry; Provide a complete set of data analysis, report, performance monitoring and information release solutions to meet the needs of scientific research enterprises; Enhance the insight and profitability of enterprises, and provide a strong guarantee for scientific research enterprises to obtain sustainable competitive advantage.Smart@Business With the functions of dashboard, query, report and multi-dimensional analysis, users can obtain information more intuitively and conveniently, and integrate various technologies into one platform Integrated environment.",
    case02_5:"System features",
    case02_6:"Flexible and dynamic index system of scientific research decision management",
    case02_7:"The multi-level hierarchical index system is composed of three parts: operation and management (scientific research input), scientific and technological innovation (scientific research output) and management support (scientific research activities), covering all aspects of the life cycle of scientific research management. Setting index audit points in the process of scientific research management can quickly grasp the current situation and potential problems of scientific research process.",
    case02_8:"Flexible and interactive ad hoc report",
    case02_9:"·Sorting and filtering of hierarchical / list report formats ·Show / hide columns ·Freeze at the beginning of row / column  ·Various graphic styles (bar chart, curve chart, pie chart, radar chart...)·Report export and publishing (Excel, PDF, XML...)",
    case02_10:"3D dynamic management cockpit",
    case02_11:"·Seamless connection with index system  ·Multiple instrument layout (left and right structure, upper and lower structure, mixed structure)  ·Cascading and drilling between indicators    ·Rich chart component library  ·Cross platform capability of Jiqian HTMLs",
    case02_12:"Visual data integration system",
    case02_13:"  ·Intuitive, drag and drop configuration interface ·Rich library of prefabricated components ·Powerful function of transformation ·Flexible implementation plan",
    case02_14:"Flexible and dynamic index system of scientific research decision management",
    case02_15:"Flexible and interactive real time report",
    case03_1:"OIL INSPECTION AND TESTING LIMS SYSTEM",
    case03_2:"The realization of sales enterprise is the reform of real management mode",
    case03_3:"Building a new mode of Intelligent Quality Supervision",
    case03_4:"Establish early warning system for product oil and gas quality",
    case03_5:"System Architecture",
    case03_6:"1. Laboratory information management department 2. Quality supervision and management system 3. Quality early warning management system 4. Product oil quality database (see technical architecture diagram)",
    case03_7:"technology roadmap",
    case03_8:"1. Unified software platform. 2. Large centralized hardware configuration mode. 3. Top level design, unified business process, unified coding system, unified construction. 4. Independent research and development of instrument connection software to achieve automatic instrument data acquisition. 5. Multi system interconnection, information fusion and sharing. 6. According to the requirements of the national second-class insurance, the security protection strategy is designed.",
    case03_9:"Application effect",
    case04_1:"GOLEAD SALES ENTERPRISE RESOURCE PLANNING (S• ERP)",
    case04_2:"Development background",
    case04_3:"objective",
    case04_4:"Guoli technology's oil sales management and analysis system is mainly developed for the oil sales industry. At present, the sales, management and financial systems of domestic gas stations are deployed separately, and each system can not be accessed. The purchase, sales and inventory data are scattered in each gas station, and there are mutual problems with the business and financial systems, so it is impossible to access the data. The purchase, sales and inventory documents of Dagao can only be produced by hand in the financial system, which reveals that the work is big, easy to make mistakes, and the work is not timely and other problems need to be solved. And in the daily business work, there are also problems such as scattered and incomplete data, incomplete purchase process and so on in the purchase, return, inventory, capital check and other businesses, resulting in a lot of work can only be managed manually.",
    case04_5:"Guoli science and technology oil sales management analysis system includes three parts: gas station sales management system, comprehensive business analysis management system, financial system. The integrated business analysis system includes four modules: integrated business management, data analysis, data collection and financial data synchronization.The system completes the data integration of the company's purchase, sales, inventory and daily business data, and automatically synchronizes the data to the financial system for certificate preparation, fund verification and other business, basically realizing the intelligence and automation of the daily application of the gas station. The business analysis module is to strengthen the thematic analysis of business according to business needs.",
    case04_6:"System Architecture",
    case04_7:"Business analysis direction",
    case04_8:"comprehensive analysis",
    case04_9:"Revenue analysis, customer unit price analysis, efficiency analysis, time analysis, card sales ratio analysis, customer consumption analysis, efficiency analysis, inactive customer analysis, sales time analysis, can storage early warning analysis, promotional gift analysis, point exchange analysis, statistical analysis, etc.",
    case04_10:"Analysis of inventory management",
    case04_11:"Suggested value table of safety stock for automatic replenishment and commodity report about to be out of stock Tables, etc.",
    case04_12:"Sales analysis",
    case04_13:"Sales list, best seller list, unsalable list, run list Oil statistical analysis table, negative gross profit report, comprehensive contribution of oil products Analysis, customer clearance, payment method, inventory turnover, etc.",
    case04_14:"Commodity analysis",
    case04_15:"Comprehensive contribution analysis, price belt analysis, convenience store diagnosis, etc.",
    case04_16:"Comprehensive analysis, inventory management analysis",
    case04_17:"Sales analysis",
    case04_18:"Commodity analysis",
    case05_1:"Integrated work platform",
    case05_2:"Multi subsystem / module, multi work cooperation",
    case05_3:"Standardization, integration, visualization and mobility",
    case05_4:"Improve the efficiency of business collaboration",
    case05_5:"Integrated work platform: it integrates multiple business subsystems, such as integrated planning, audit system, key work, integrated services, oil sample inspection, personnel management, work tasks, etc., and combines with mobile office to complete the transformation from multiple systems to one platform information work. At the same time, through mobile office, it improves the efficiency of collaborative work of various businesses.",
    case05_6:"Comprehensive plan",
    case05_7:"It includes two modules: comprehensive plan, work execution and evaluation. The closed-loop management starts from the formulation of comprehensive plan, feeds back the process of comprehensive plan, and finally evaluates the completion of each index of comprehensive plan and work execution.",
    case05_8:"Tasks",
    case05_9:"The task is to help the management to follow up each important task. The task executor can report the progress and summary of the task to the management in time, so as to ensure the results of each task and improve the efficiency of team collaboration.",
    case05_10:"Integrated services",
    case05_11:"Standardize the use of official vehicles, public houses and meeting rooms of the company, and strengthen the management and supervision of official vehicles, public houses and meeting rooms.",
    case05_12:"Oil sample inspection",
    case05_13:"Change the current working mode of oil sample inspection application in gas station, logistics storage center and transportation department, and complete the whole process of electronic application for oil sample inspection from application to approval.",
    case05_14:"personnel management",
    case05_15:"Change the current staff leave and deal with, use the work mode of private abroad certificate management, complete these work from the application to the approval of the whole process of electronic.",
    case05_16:"Audit management",
    case05_17:"Formulate audit work plan and carry out audit work management, find out weak links and key risk points in operation and management as soon as possible, and take effective measures to actively rectify.",
    case06_1:"Performance appraisal system",
    case06_2:"The fast changing market environment and increasingly fierce competition pressure force enterprises to pay more and more attention to performance The concept of 'reason' is more and more popular. In the future competition, only those who focus on building core competitiveness, continuous improvement and innovation  Only by improving performance and forging lasting competitive advantage can enterprises gain a favorable position in the market competition. From the perspective of establishing and improving enterprise performance appraisal and work improvement mechanism, Guoli technology follows the principle of 'work performance first, process first' Combined with the results, the principle of 'unified responsibility and power, fair distribution, pay according to work' is developed, which integrates the subjective and qualitative evaluation And objective quantitative evaluation of the performance appraisal system for modern enterprise human resource management provides the best solution.",
    case06_3:"System features",
    case06_4:"The assessment system is dynamic and flexible to adapt to management differences",
    case06_5:"Build a flexible performance appraisal system, which can adapt to 360 degree evaluation, post KPI, balanced scorecard, target management and other appraisal methods. The index system of classification and stratification has good expansibility and adaptability. It supports objective quantitative indicators and subjective qualitative indicators. It can define index association, weight and score interval. It can customize the template of appraisal scoring table, Can adapt to different positions, different business areas, different assessment methods",
    case06_6:"Subjective and objective evaluation complement each other",
    case06_7:"Flexible setting of assessment subject, adapting to the project system and matrix assessment environment, supporting the dynamic weight of assessment subject, unique salary point contribution rate model, releasing the quantitative benchmark value of post work target, supporting the objective assessment target quantitative algorithm, and achieving the accuracy of objective assessment",
    case06_8:"system function",
    case06_9:"Multi dimensional display of performance analysis",
    case06_10:"Employee view (personal performance file, performance capability analysis, etc.); management view (performance ranking, assessment progress query, etc.); rich analysis methods (comparative analysis, trend analysis, proportion analysis, etc.)",
    case06_11:"system architecture",
    case06_12:"Evaluation index system management realizes the classification, hierarchical, attribute and quantitative management of performance evaluation indexes. Employee performance scoring takes performance evaluation index system as the core, relies on standardized performance process platform and rich evaluation model,",
    case06_13:"Subjective and qualitative evaluation system for multi dimensional evaluation",
    case06_14:"Quantitative assessment of work tasks of technical posts uses the data of quantitative decomposition, distribution and accounting of work tasks in the core business system to assess the contribution of employees and realize a scientific and accurate objective quantitative evaluation system, The external system interface uses ESB (Enterprise Service Bus) and automatic data extraction technology to realize the automatic loading of basic data for performance appraisal, which provides data support for the objectivity and accuracy of performance appraisal",
    case07_1:"International roaming user link acceleration platform",
    case07_2:"Enhance the user experience of Guoman's on camera navigation",
    case07_3:"Enhance the user viscosity of international roaming service",
    case07_4:"Provide mature application software to realize core functions",
    case07_5:"The system will support high concurrent connection, different VPN protocols, multi link switching between domestic and overseas, association of CDN user traffic with GTP tunnel information, different load balancing strategies, SNI blacklist, SNI whitelist, dynamic switching of SNI blacklist, user traffic audit and hot switching, Support back-end forwarding service survivability detection, support back-end server survivability detection.",
    case07_6:"The socket forwarding module with high concurrency is implemented based on the coroutine technology; Based on the multi-layer forwarding technology, the flexibility and reliability of the forwarding system are increased; Based on transparent encryption technology to solve the problem of firewall depth detection. The model traffic of a specific IP can be controlled in real time to block the communication of the malicious traffic. Based on the high-performance IP address matching engine, the IP in the blacklist is identified, and the corresponding traffic is controlled by the traffic control module. The implementation scheme of CDN acceleration system is shown in the figure below.",
    case08_1:"Enterprise's integrated work platform enterprise wechat mobile application",
    case08_2:"Comprehensive planning platform includes comprehensive planning, work execution and assessment",
    case08_3:"Mobile office gets rid of the restriction of office environment",
    case08_4:"The comprehensive plan platform includes three modules: comprehensive plan, work execution and assessment. The comprehensive plan and assessment system includes the closed-loop management which starts with the formulation of comprehensive plan, feeds back the process of comprehensive plan, and finally evaluates the completion of comprehensive plan and work execution.",
    case08_5:"Mobile office gets rid of the constraints of the office environment, and the relevant personnel of each platform can deal with normal work or emergencies more timely and efficiently",
    case09_1:"Enterprise oriented fault operation and maintenance processing platform",
    case09_2:"Rapid response and centralized control",
    case09_3:"Automatic record, AI robot interaction",
    case09_4:"Paper office, online Q & A",
    case09_5:"At present, the way of software and hardware maintenance is still stuck in the traditional way that the problem is all by roaring, the training is all by running, the test paper is all by paper, and the learning is all by speaking.",
    case09_6:"The intelligent shared operation and maintenance 400 management platform is used to integrate all kinds of operation and maintenance workflow, which not only unifies the after-sales maintenance methods of various manufacturers, but also strengthens the management of maintenance process. At the same time, it adds a variety of auxiliary functions, such as AI robot for customer service, online examination on mobile terminal, question bank on mobile terminal, etc.",
    case09_7:"Build a shared operation and maintenance platform with fast response, centralized management and control, automatic filing, AI robot interaction, paperless office and online Q &amp; A.",
    case09_8:"1. The operation and maintenance platform with data sharing and AI voice intelligence can realize the purpose of man-machine combination, improving service quality and operation and maintenance data sharing. Realize the automation and intelligence of operation and maintenance.",
    case09_9:"2. Text, voice intelligent query, AI robot interactive message.",
    case09_10:"3. Problem view and analysis.",
    case09_11:"4. Knowledge base construction of shared operation and maintenance platform",
    case09_12:"The platform mainly includes four functional modules: fault reporting, self-service search, statistical analysis and learning garden. According to the platform, it is divided into two parts: data management system and enterprise wechat.",
    case10_1:"Engineering construction management system",
    case10_2:"Unified platform, unified presentation, multiple applications",
    case10_3:"Set reasonable process checkpoints to drive project management process",
    case10_4:"Powerful statistical report and data analysis function",
    case10_5:"The construction period of large-scale projects is long, involving a wide range of units, many construction projects, and various types of projects. The project management organization and personnel are lack of effective macro management means and tools. The project construction management system independently researched and developed by Guoli science and technology, with innovative 'multi-level supervision' To realize the standardized, systematic and scientific management of engineering construction. It standardizes the management of project plan implementation, fund payment and project archives, ensures complete and complete project archives, and provides comprehensive, complete and scientific basis for project settlement, audit, audit and sustainable development.",
    case10_6:"System objectives",
    case10_7:"Establish a sound project management mechanism, standardize the project management business process, integrate project resources, avoid project implementation risks, achieve the expected objectives of the project, and improve the overall level of project management. To ensure the authenticity and accuracy of the project construction cost, we should establish the fund payment procedure suitable for government investment, fully exercise the responsibilities of the project management organization, strengthen the financial expenditure management, and improve the supervision and restriction mechanism. Establish a set of file management system in line with the implementation of the project to realize the centralized and unified management, utilization, data specification, security protection and data support of paper and electronic file information.",
    case10_8:"system architecture",
    case10_9:"Unified platform, unified presentation, multiple application infrastructure services, business application services and information presentation services run independently, and do not affect each other. Based on the service-oriented infrastructure platform, automatic process services are built to control various business processes, from linear and structured processes to collaborative, special processes or a mixture of the two",
    case10_10:"Efficient work collaboration services - create a virtual workspace for the project team, which brings together relevant personnel, processes and content. Powerful statistical query service   Provide flexible and efficient statistical query tools. Intuitive report service   It provides an easy-to-use and efficient rapid report design scheme. Flexible user rights service - design base according to project organization structure and business process system. Authorization and authentication mechanism based on role",
    case10_11:"It supports the management and retrieval of structured data, unstructured data and semi-structured data. It has a complete life cycle management of archives. It conforms to the national archives management standards, Support traditional / simple filing mode, powerful statistical report and data analysis function, excel like data report function, support sorting, screening, hiding, freezing and other functions, graphical OLAP analysis, multi-dimensional and multi angle analysis chart, original project management KPI project management cockpit, project progress, cost and other macro data at a glance.",
    case10_12:"System features - project template based on dynamic form, adapt to different types of project management mode",
    case10_13:"Support the sub project decomposition / task decomposition / process decomposition and other various project models, flexible and easy to use customized project process templates to adapt to different project processes.",
    case10_14:"System features - set reasonable process checkpoints to drive the project management process",
    case10_15:"Complete project management process, covering the whole process of closed-loop management of project reserve, project approval, project implementation and project acceptance. Flexible business approval process, monitoring of each implementation process, record, warning and traceability system with contract payment as the core",
    case11_1:"Communication engineering construction site management system",
    case11_2:"Construction management task decomposition based on WBS",
    case11_3:"What's the whole cycle of communication engineering? Refined 'double closed loop' management system",
    case11_4:"The infrastructure construction of telecom operators is a project with large investment scale, short construction period, many participating units, high technical quality requirements and complex management. During the whole construction period, it involves many contents such as construction plan, construction progress, construction quality, construction safety, equipment installation and data management. However, due to the scattered field construction sites, inconvenient transportation and other objective conditions, the site management is difficult, the cost is high, and the management mode is too extensive, so that there is no basis for the construction unit to follow the implementation process requirements; It is impossible to monitor whether the inspection is in place or not, and it is impossible to verify the supervision responsibility; The progress of the project can not be truly grasped; The results of quality rectification can not be effectively tracked.",
    case11_5:"In view of the above problems, the communication engineering construction site management system independently developed by Guoli science and technology fully integrates the characteristics of communication engineering. Focusing on the progress, quality and documentation of the engineering project, it realizes the fine management and control of the site management in the implementation stage of the project, and implements dynamic, quantitative and systematic management and control of the project, so that the owner, the supervisor, the project manager, the project manager, the project manager, the project manager, the project manager, the project manager, the project manager, the project manager, the The construction and supply parties should timely understand the information of project construction, and improve the efficiency of project schedule / quality control“ The refined management of communication engineering improves both construction efficiency and investment benefit",
    case11_6:"Function introduction construction management task decomposition based on WBS",
    case11_7:"The project is broken down into procedures and construction sections, and the management responsibility is assigned to the person. The preset process template realizes professional support, covering wireless and transmission majors. It conforms to the hierarchical management system of telecom operators, and supports the management and control responsibilities, control points and control methods of the relevant stakeholders in the process of project construction by the engineering construction management organizations at all levels.",
    case11_8:"System features - refined ’double closed loop‘ management system for the whole life cycle of Communication Engineering",
    case11_9:"Based on the whole life cycle management idea, realize the ’big closed-loop‘ management of communication engineering construction from planning, planning, project approval, design, procurement, construction, preliminary inspection and maintenance, trial operation, final inspection and post evaluation. Based on the ’two eight rule‘, set up the key links / key control scenarios of engineering construction, and clarify the specific control requirements of each key control link, Complete and flexible internal control process, including commencement declaration, acceptance and approval, process inspection, project weekly report, project change, etc.",
    case11_10:"System features - innovative mobile phone inspection mode extends the management tentacles to the construction site",
    case11_11:"The ’geotagging‘ technology enables photos to be attached with geographic information labels and time stamps, so as to eliminate fraud and improve the efficiency of supervision, Reduce the cost of the field} through the track reappearance function, master each inspection path, provide the basis for the performance evaluation of field personnel} multi-dimensional, multi vision} project information real-time update",
    case12_1:"OIL SALES BUSINESS AUDIT MANAGEMENT SYSTEM",
    case12_2:"Flexible and scalable",
    case12_3:"Refined 'double closed loop' management system",
    case12_4:"Rich data analysis functions to assist decision making",
    case12_5:"With the business development of oil sales enterprises, it is the top priority to improve the internal control rules and implement the internal control standards. Internal control has strict provisions on the recognition of income and the standardization and rationality of enterprise operation. Strengthening business audit and ensuring the integrity of income are the internal needs of enterprises to improve the governance structure. In order to meet the needs of internal control and reduce potential risks, audit will become an important link in the daily management of enterprises.",
    case12_6:"From the perspective of oil sales industry, the audit management system of sales business of oil enterprises developed by Guoli science and technology is designed with reference to the basic business operation mode of thousand oil sales enterprises, involving in product oil, non oil, lubricating oil, small products, logistics and other business fields. Through the construction of a reasonable system, the daily business relationships of multiple channels are vertically connected, The purpose is to make the operational risk problems of the whole oil sales industry chain clearly visible, so as to take targeted audit measures to reduce or improve the internal operational risks of oil sales enterprises, strengthen supervision and avoid resource waste.",
    case12_7:"System objectives",
    case12_8:"Improve the ability of risk monitoring and early warning of the Audit Department of oil sales enterprises, timely find out the existing risks and effectively prevent them, correct the existing violations, improve the audit methods, improve the audit efficiency, standardize the audit operation, and strive to realize the whole process of audit systematization, so that the audit work can gradually develop in a standardized, scientific and orderly direction, So as to achieve a comprehensive improvement of audit efficiency and quality.",
    case12_9:"Product function",
    case12_10:"High efficiency and stability",
    case12_11:"The self-developed ETL tools are integrated into the whole system, and multi-element technical solutions such as self scheduling, error handling, quality inspection, performance monitoring, multi-channel support and log analysis are used to escort the audit management system.",
    case12_12:"System features - flexible and scalable",
    case12_13:"·The dynamic whole business audit system realizes the classification and hierarchical management of audit items in the audit system. According to the audit objectives and audit contents of different time periods, the template of audit work order can be customized to flexibly adjust audit items· The organization management system adapted to the audit work can realize the dynamic management and hierarchical management of the organization. It is especially suitable for the diversified and changeable demand of the sales terminal of oil sales enterprises· Based on the three-dimensional weighting structure of audit project and audit object, the dynamic weighting of post role is realized.",
    case12_14:"System features - fine closed loop management mode",
    case12_15:"·The audit management system adapts to the current situation and development of the business, supports various types of audit workflow, and realizes the closed-loop management mode of ’plan inspection rectification review‘· Based on the event tracking system of case, we can trace back the thousand problems.",
    case12_16:"System features - rich data analysis to assist decision making",
    case12_17:"·The system provides a wealth of data analysis functions, which can classify and screen the problems in a period of time, and show the security risks involved in the high incidence period to the managers without reservation. The managers can make corresponding adjustment measures for the problems through the analysis results· The audit work evaluation system enables leaders to have a comprehensive understanding and grasp of the audit work, query and count the audit results of the auditors and the audit objects.",
    case13_1:"GAS STATION OPERATION MONITORING MANAGEMENT SYSTEM",
    case13_2:"Sort out the daily report of each business system",
    case13_3:"Combined with gas station budget data",
    case13_4:"Daily profit monitoring model of gas station based on definition",
    case13_5:"Sort out the daily reports of gas stations in various business systems (including HOS, FMIS, IC card, e-Voucher query system, etc.), combine the budget data of gas stations (mainly expense budget or last month expense data), according to the defined daily profit monitoring model of gas stations, calculate the daily gross profit and profit of gas stations through various data such as sales volume, revenue, cost, discount, expense, etc, Form daily profit monitoring report. The daily monitoring report of gas station profit is divided into two parts. The first part, based on the sales revenue of oil products, calculates the cost, discount, expense allocation, etc., and forms the daily monitoring report of oil product profit. In the second part, on the basis of daily monitoring of oil profit, the non oil revenue, cost and gross profit provided by financial statements are added to form daily monitoring report of gas station profit.",
    case14_1:"AMOEBA BUSINESS APPLICATION",
    case14_2:"Business accounting",
    case14_3:"Performance accounting",
    case14_4:"Business analysis",
    case14_5:"Calculate the daily performance of employees in each post through the main business indicators of amoeba in the service station. The service station master can adjust the performance weight according to the post / personnel contribution. After adding the monthly evaluation coefficient, the performance salary of each employee in the current month can be calculated accurately. At the quality and efficiency level of grass-roots operation and management of poly tar stations, carry out the development of various special analysis functions such as gas station sales analysis, gas station non oil revenue analysis, gas station cost analysis, gas station profit analysis, gas station work efficiency analysis and benchmarking station comparison analysis.",
    case14_6:"Through the daily business data accounting of the service station, the daily business performance accounting of the service station is realized. Comprehensively evaluate the quality and efficiency of service station operation through data analysis, and guide optimization and improvement.",
    case14_7:"The operation and management of the gas station needs to master the overview of the business indicators of the gas station on that day every day, evaluate the sales quality and efficiency according to the business data of the gas station, and provide the basis for personnel performance accounting.",
    case14_8:"According to the daily business accounting results of the gas station, compare the achievement of the gas station budget and business indicators, and import the post performance algorithm of the gas station. Check the performance of the service station and employees every day to achieve the effect of benign competition and direct incentive.",
    case14_9:"Use the 'amoeba' business analysis tool to quickly find the parts to be optimized in the business data of the benchmarking station, and make up the gaps. Stimulate the independent operation and management of each service station, and guide the profit and benefit improvement of the service station",
    case15_1:"Mobile core network service linkage system",
    case15_2:"Program monitoring module",
    case15_3:"Flow backtracking module",
    case15_4:"Data report module, etc",
    case15_5:"The system will support the processing of authorization information submitted by users through app, the maintenance of domain name rules, the dynamic switching of domain name rules, the batch opening of authorization in specific direction, the monitoring of user's authorization validity period, the automatic renewal of invalid authorization, the automatic cache submission of authorization, and the repeated submission of failed authorization, It supports the export of visiting user information list, the push of visiting user information list, and the distributed retrieval of specific user associated information. The functional module diagram of national power business linkage system is shown in the figure below.",
    case15_6:"In the aspect of business linkage and collaboration, it can realize the unified configuration management of network element and black and white list, DNS rules, and user permission information, and then real-time monitor and guide the overseas map access behavior of international roaming users, and timely warn the existing risks. Finally, based on the analysis data of each dimension, the situation is presented in the form of map, chart and so on. At the same time, it connects with the operator's data roaming policy control system and business support system, provides the analysis result data of the platform, and synchronously receives the user's traffic packet information data and the control strategy issued by the policy control system in real time.",
    case16_1:"Mobile network specific user traffic location system",
    case16_2:"High performance packet capture module",
    case16_3:"Network behavior analysis module",
    case16_4:"Business data processing module, etc",
    case16_5:"The system supports high-speed capture of GTP packets, deep parsing of DNS, SSL, quic and HTTP packets by gtpu, session caching of gtpc, TEID caching of gtpu, real-time association between gtpc and gtpu tunnel, session information query of gtpc and bypass packet injection, Support for Android system's no perception traffic traction function, as well as specific application's domain name blacklist, specific application's domain name whitelist and other functions. The function module diagram of national power specific flow guidance system is shown in the figure below.",
    case16_6:"Based on the unique technologies of zero copy and ring queue without lock, the system implements a high performance packet capture engine. When dealing with large traffic GTP protocol, we need to customize parallel GTP protocol stack. On the one hand, we need to implement high-performance protocol stack; On the other hand, it needs to support the GTP session keeping function to realize the information association between gtpc and gtpu tunnels. DNS hijacking message should comply with the tunnel protocol specification, based on the statistics of uplink and downlink information of data stream, and should be injected into the link before the real response message arrives, which requires high speed of protocol parsing and message encapsulation. The implementation scheme is shown in the figure below.",
    case17_1:"GAS STATION SINGLE STATION ACCOUNTING AND ANALYSIS PLATFORM",
    case17_2:"Establish shared database",
    case17_3:"Pooling existing financial",
    case17_4:"Data of zero management and other business systems",
    case17_5:"Background",
    case17_6:"It is difficult to allocate the cost to specific gas stations. There is no unified evaluation standard for different types of service stations. The daily service station operation and management report is semi manual. It is difficult to stack and compare the periodic operation data of the service station.",
    case17_7:"Content",
    case17_8:"Establish a shared database to collect the data of existing financial, zero management and other business systems. Establish the index system of gas stations and branches, complete the accounting of various business indicators of gas stations, and make a comprehensive and objective evaluation of the actual business conditions of gas stations and branches.",
    case17_9:"System architecture - Achieving Goals",
    case17_10:"Business unit",
    case17_11:"·Restore the financial book data in a single station to standardize single station accounting· Accurate monitoring of operating data of oil and gas non business and decision support of marketing activities· Realize the comprehensive reflection of the efficiency creation ability of a single gas station and the optimization of business strategy.",
    case17_12:"Branch Manager",
    case17_13:"·Build a gas station budget management system, realize benchmarking analysis of gas stations, change work management ideas from performance to indicators, and improve work efficiency.",
    case17_14:"Service station operator",
    case17_15:"·Timely understand the composition of business indicators and the completion of budget indicators, and carry out early warning of business indicators to find problems or shortcomings.",
    case18_1:"TECHNICAL SUPPORT AND GUARANTEE",
    case18_2:"With the application of unified and self built systems of CNPC, information system has become an indispensable tool for operation and management. In order to meet the needs of daily information system operation and maintenance, handle the relationship between technical service and use, do a good job in system construction and system maintenance, and ensure the safe, stable and reliable operation of the information system, technology is needed to provide all-round guarantee service support for the safe and stable operation of the overall business system. As a system operation and maintenance unit, our company has carried out system operation and maintenance in four provinces, which has played a strong supporting role in the development of enterprise information work.",
    case18_3:"Service process",
    case18_4:"Training process",
    itccase01_1:"Construction of data center supporting environment",
    itccase01_2:"As the bearing environment of information network, the computer room of data center is an important basic platform to support all kinds of information data calculation and processing. The reliable operation of the information system depends on the strict environmental conditions (temperature, humidity, cleanliness and control accuracy) and working conditions (anti-static, shielding, fireproof, etc.) of the data center computer room.",
    itccase01_3:"The integrated solution for supporting environment of Golead data center includes the construction of the following subsystems: (1) facade construction of computer room（ 2) Power distribution and lighting engineering（ 3) Air conditioning fresh air project（ 4) Computer room environment monitoring system engineering（ 5) KVM centralized management system（ 6) Unified monitoring display unit project（ 7) Lightning protection and grounding engineering（ 8) Gas fire fighting engineering of machine room（ 9) Cabinet and generic cabling engineering（ 10) Site reinforcement works（ 11) Low voltage distribution and uninterruptible power supply engineering.",
    itccase02_1:"Generic cabling services",
    itccase02_2:"In the past IDC integrated service delivery process, Golead has accumulated comprehensive service experience in data center integrated wiring design, laying, wiring, jumper, maintenance, etc. It can provide customers with advanced wiring, compatibility, openness, flexibility, reliability and economy of the integrated wiring service program.",
    itccase03_1:"Dynamic environment and video monitoring system",
    itccase03_2:"Together with leading data center environmental monitoring equipment manufacturers, Golead provides customers with integrated data center power and environmental monitoring management system solutions.",
    itccase03_3:"Help customers to monitor and manage the power equipment and environment of the data center room in real time: obtain the equipment operation information in real time, receive all kinds of alarm signals at the first time, so as to realize the effective avoidance of security risks.",
    itccase03_4:"The main functions of dynamic environment monitoring center include:",
    itccase03_5:"1) Receive the relevant parameters (running status, working parameters, alarm information, etc.) of the equipment and environment monitored by the front-end acquisition program, and display them to the user in real time;",
    itccase03_6:"2) Collect the front-end device alarm events, inform the user in the way of warning on the interface, and release the specific contents of the alarm to the relevant management personnel in the way of voice, telephone, mobile phone, SMS, etc;",
    itccase03_7:"3) It can provide statistical query, analysis, report and other auxiliary management applications for the collected data and alarm events.",
    itccase03_8:"4) Send control commands to the front-end acquisition program to control the start and stop of related equipment or related operations;",
    itccase03_9:"5) Provide remote monitoring and management function.",
    itccase03_10:"The detailed functions of the dynamic environment monitoring center also include:",
    itccase04_1:"Equipment inspection and emergency treatment",
    itccase04_2:"Based on our experience accumulated in data center technical service projects for many years, our company can provide customers with directional equipment inspection or fixed cycle IDC comprehensive inspection service. Assist customers to find and solve problems actively, put forward improvement suggestions for the operation and maintenance of user information equipment, and provide preventive reports to ensure the continuous and stable operation of user system.",
    itccase04_3:"The resident engineer team of Guoli science and technology has rich experience in maintenance and service of all kinds of common equipment and systems in the data center, and knows well the normal service life of all equipment and systems in the data center and the replacement frequency of common spare parts; Therefore, according to the current situation of user equipment and practical application, the spare parts library for specific customers can be designed for the unexpected needs of operation and maintenance services.",
    itccase04_4:"Golead technology can provide emergency response service for data center. When customers encounter unforeseen accidents and failures in a series of project activities, such as network cutover, equipment expansion and upgrading, new system commissioning, data migration, etc., they can go to the site to assist customers with troubleshooting and emergency treatment in the first time.",
    itccase05_1:"Installation and delivery of ICT equipment",
    itccase05_2:"Golead technology has established in-depth cooperative relations with many domestic and foreign communication operation enterprises, large IT equipment suppliers and cloud manufacturers. It has the capability of all-round technical support and service covering ICT. It provides a series of solutions for communication industry enterprises and their large customers, such as data center deployment and construction, integrated wiring, intelligent building, power and monitoring system construction, pop point construction, etc.",
    itccase05_3:"At present, the main countries and regions covered by the services are as follows:",
    itccase05_4:"China, Hong Kong, Singapore, Japan, South Korea, Australia, New Zealand, Philippines, Indonesia, Vietnam, Malaysia, Pakistan, Thailand, etc;",
    itccase05_5:"The United States, Canada, Mexico, etc;",
    itccase05_6:"Germany, Britain, France, Russia, etc;",
    itccase05_7:"Nigeria, South Africa, Egypt, etc.",
    itccase06_1:"Global logistics customs clearance",
    itccase06_2:"As a one-stop ICT solution provider, Guoli technology focuses on the development of international logistics and customs clearance resources while providing high-quality technical services to domestic and international customers. At present, with the support of partners, Guoli science and technology has achieved agile logistics and customs clearance service capabilities in more than 100 countries and regions around the world. Major countries in the Asia Pacific region will arrive within 5 working days to provide customers with full logistics, customs clearance and tax payment services.",
    itccase07_1:"7 * 24 on site service",
    itccase07_2:"Golead provides customers with a full range of ICT field service solutions. It includes data center operation guarantee service, business application service for end users, infrastructure service and information resource management service, covering it operation maintenance and guarantee from all levels and aspects of IT infrastructure, business application system and information security.",
    anlitext01:"M international roaming link acceleration project of Chinese operators",
    anlitext02:"Pop point construction project of M Asia Pacific region of Chinese operators",
    anlitext03:"China operator m Japan VPN expansion project",
    anlitext04:"European pop point expansion project of Chinese operators",
    anlitext05:"Alibaba Asia CDN project",
    anlitext06:"ZTE Europe CDN project",
    anlitext07:"DCIM project of China operator m America and Singapore",
    anlitext08:"China operator C Asia Pacific IDC generic cabling",
    anlitext09:"China operator m Hong Kong computer room wiring operation and maintenance project",
    anlitext10:"Equipment inspection project of South China data center of an oil group in China",
    anlitext11:"Data center operation and maintenance project of a multinational oil joint venture",
  },
  user: {
      login:'login',
      register:'register',
      loginUsername:'please input email or phone',
  }
}