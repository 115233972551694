module.exports = {
  language: {
      name: 'En'
  },
  title:"国力科技",
  Nav:{
    home:"首页",
    abus:"关于我们",
    cpfa:"产品及解决方案",
    itc:"面向全球ICT服务能力",
    news:"最新动态", 
    joinus:"加入我们",
    qiyegaishu:"企业概述",
    qiyeyuanjing:"企业愿景",
    zhuanyezizhi:"专业资质",
    qiyezonghehuaguanli:"企业综合业务",
    nengyuanshihuaguanli:"能源石化业务",
    guojiyunying:"运营商业务",
    dashuju:"大数据应用",
    xitongjishuyuzhichi:"技术支持与保障",
    quanfangweiitc:"全方位IDC服务",
    quanqiuopo:"全球ICT服务能力",
    anlifenxiang:"案例分享",
    zhaopin:"招贤纳士", 
    peixunyufazhan:"培训与发展",
    lianxiwomen:"联系我们",
    shenghuoyufuli:"生活与福利",
    danwei:"单位：广州市国力计算机科技有限公司",
    dizhi:"中国大陆：广州市天河区东莞庄2号财润国际大厦3025-3029",
    dizhi2:"中国香港：香港葵青区葵涌大连排道21/33号",
    dizhi2_1:"宏达工业中心8楼18单位",
    dizhi3:"韩国：韩国首尔市中区茶山路 223 号 新堂大厦5F 546",
    fazhanlicheng:"发展历程",
    cairun:"新加坡：新加坡宏茂桥62街 Link@AMK #07-05",
    dianhua:"电话：020-87294662",
    banquan:"©2011-2021 广州市国力计算机科技有限公司 版权所有 粤ICP备15067360号-1",
    yuyan:"切换语言：En",
    qygstext1:"",
    youjian:"邮箱",
    hezuogongsi:"合作公司",
    qiyewenhua:"企业文化",
    indexText01:"管理即是处理人的关系",
    indexText02:"建设中国石化能源管理系统，提升能源管理精细化水平",
    indexText03:"链路聚合技术",
    indexText04:"大数据，IT行业的又一次技术变革",
    indexImgText01:"机房基础建设/综合布线/设备与集成系统交付",
    indexImgText02:"动环及视频监控/设备巡检与应急处理/数据中心运行管理与维护",
    indexImgText03:"覆盖亚洲、欧洲、北美",
    indexImgText04:"中国移动国际漫游用户GOOGLE地图服务",
    indexImgText05:"中国移动国际公司将军澳机房布线维护",
    qiyegaishuText01:"国力科技是从事软件及信息产品开发、系统集成和信息服务的民营高科技企业。国力科技主营业务包括：软件开发、系统集成及技术服务，旨在为用户提供全面的IT解决方案。",
    qiyegaishuText02:"不断提高员工物质精神饱满度，为社会发展贡献力量。",
    qiyegaishuText03:"ISO 9001质量管理体系认证证书、ISO 27001信息安全管理体系认证证书、ITSS信息技术服务标准符合性证书、广州市软件企业认定证书、高新技术企业证书、拥有30余项软件著作权证书",
    qiyegaishuText04:"广州市国力计算机科技有限公司（简称国力科技）是从事软件及信息产品开发、系统集成和信息服务的民营高科技企业。国力科技主营业务包括：软件开发、系统集成及技术服务，旨在为用户提供全面的IT解决方案。",
    qiyegaishuText05:"国力科技位于广东广州，成立于2011年。注册资本500万元，软件及服务年收入3000万，公司员工大于60人。公司部门：研发部、市场部、运维部、财务部、河南事业部、湖南事业部、海南事业部。 软件及服务年收入3000万，公司员工大于60人。公司部门：研发部、市场部、运维部、财务部、河南事业部、湖南事业部、海南事业部",
    qiyegaishuText06:"国力科技顺应“互联网+”的发展趋势，积极开拓移动互联网的技术领域，已涉足电子商务应用、互联网金融、O2O商业系统及自媒体营销等多个领域，涉及服装行业、金融行业、广告行业等。",
    qiyegaishuText07:"国力科技引入国际最佳实践和方法理论，结合多年的行业数据中心实际运维管理经验，形成一套成熟的IT运维服务体系和规范，基于ITIL流程框架，实现了IT管理与业务服务的融合,为多家央企提供优质的IT基础架构运营管理服务。",
    qiyegaishuText08:"人爱天敬 简单、专注、勤奋、实干",
    qiyeyuanjingText01:"企业使命",
    qiyeyuanjingText02:"以科技利益人类 以成就赢得尊敬",
    qiyeyuanjingText03:"企业精神",
    qiyeyuanjingText04:"以科技利益人类 以成就赢得尊敬",
    qiyeyuanjingText05:"企业宗旨",
    qiyeyuanjingText06:"对客户负责 对员工关爱 对股东回报 对社会贡献",
    guolizizhi:"国力资质",
    zizhi01:"质量管理体系认证证书",
    zizhi02:"信息安全管理体系认证证书",
    zizhi03:"信息技术服务管理体系认证证书",
    zizhi04:"软件企业证书",
    zizhi05:"高新技术企业证书",
    zizhi06:"软件著作权证书",
    qiyewenhua01:"专业、年轻、有梦想、行动力强",
    qiyewenhua02:"团队是指拥有共同目标，并且具有不同能力的一小群人有意识的协调行为或力的系统，这群人就如同人的五官一样，共同协作维持一个人的生存，缺一不可。",
    qiyewenhua03:"我们是一支专业的团队。我们的成员拥有多年的信息安全专业技术背景，来自国内知名安全公司的一线骨干。",
    qiyewenhua04:"我们是一支年轻的团队。我们的平均年龄仅有26岁，充满了朝气和创新精神。",
    qiyewenhua05:"我们是一支专注的团队。我们坚信，安全的品牌源自客户的信任。只有专注，才能做好安全。",
    qiyewenhua06:"我们是一支有梦想的团队。我们来自五湖四海，因为一个共同的梦想：做一家真正优秀的信息安全企业，为客户提供最可靠的互联网安全防护。",
    qxfz01:"行业交流",
    qxfz02:"课程培训",
    qxfz03:"员工入职培训",
    qxfz04:"读书分享会",
    qxfz05:"技能培训",
    qxfz06:"沙龙",
    qxfz07:"提供新员工入职培训，老员工进修学习机会",
    jinyibuliaojie:"进一步了解",
    shfl01:"员工福利",
    shfl02:"工是公司的宝贵财富",
    qxfz08:"社保包括养老、工伤、生育、失业、医疗五险一金，按员工归属地的当地政策进行办理。假期：法定节假日、婚假、丧假、产假、年休假、病假。",
    sdhujuzhongxin:"数据中心管理系统",
    sdhujuzhongxin01:"信息资源整合与重组",
    sdhujuzhongxin02:"多级、多纬的信息系统目录体系",
    sdhujuzhongxin03:"多视点的运维状态展示",
    case01_1:"产品特点",
    case01_2:"完善可靠的大数据采集体系采用开放式、通用性的设计，支持主流数据库平台、多种数据载体格式和数据结构的数据采集卓越的数据采集性能，丰富的函数转换算法库，提高数据预处理效率拖拽式的可视化配置界面，实现“一站式” ETL过程的管理。·	高性能的数据服务平台·	SOA服务框架实现实时的数据共享服务·	数据推拉(Push&Pull)技术助力·	'端到端'的数据交换服务多重策略保证信息资源权威性、来源控制策略、权威序列策略、后更新时序策略、冲突稽核策略",
    case01_3:"信息资源整合与重组",
    case01_4:"信息资源整合与重组是—种信息资源优化组合的存在状态。依据—定的需要，对离散的、多元的、异构的、分布的信息资源进行类聚和重组，重新结合为—个新的有机整体，形成—个效能更好、效率更高的新的信息资源体系，适应以用户为中心的个性化信息服务模式。",
    case01_5:"多级、多维的信息资源目录体系",
    case01_6:"信息资源目录体系是政务信息资源提供服务的窗口。Smart@Data采用元数据技术对信息资源特征进行描述形成统—规范的目录内容，通过对目录内容的有效组 织和管理，形成信息资源物理分散、逻辑集中的信息共享模式，提供信息资源的发现定位服务。",
    case01_7:"多视点的运维状态展示",
    case01_8:"集合仪表盘、KPI技术的运维管理驾驶舱，能准确了解数据中心整体运行情况以事件为核心的运维日志支持声音、邮件、短信的实时故障通知。",
    case01_9:"国力科技自主研发的数据中心管理系统应用当今流行的大数据技术，逐步建立政府和社会互动的大数据形成机制。旨在打开政府各部门间、 政府与公众间的边界，消除信息孤岛现象，促成数据交换和共享，充分发掘信息的附加经济价值，提升政府社会治理能力和公共服务能力。在当今大数据时代，数据规模也迅速增长，数据已渗透到各行各业，成为重要的生产因素。数据演进体现出巨大的商业价值和社会价值，与生产力的提高有着直接的关系。随着近几年电子政务的蓬勃发展，各政府部门已积累了大量的政务信息，但条块分割的体制使信息孤岛的出现成为了必然。基于Smart@ Data的政府数据中心具备三大职能。",
    case02_1:"科研管理决策支持系统",
    case02_2:"科研管理是集成科技创新及推广应用新技术的重要环节，对千推动科研与经济结合，实现产业升级和经济结构调整具有不可替代的作用。 科研管理决策支持系统是国力科技在自主知识产权的Bl平台上推出的 套适用千科研企业的经营决策系统。 它是协调科研管理各方关系，有效地利用人、 财、 物等科研资源，理顺科研活动中各要素之间关系的重要保障。",
    case02_3:"系统介绍",
    case02_4:"Smart@Business是企业级商业智能应用平台，凝聚了多年的商业智能最佳实践经验，整合科研行业的数据分析和决策支持的需求；提供 整套满足科研企业需求的数据分析、报表、绩效监控和信息发布的决决方案；增强企业的洞察能力、盈利能力，为科研企业获得可持续的竞争优势提供强大的保障。Smart@Business具有Dashboard (仪表盘）、Query (灵活查询）、Report (业务报表）、Analysis(多维分析)等功能，用户可以更直观、更便捷的获取信息，开创性地把各种技术整合到—个集成环境中。",
    case02_5:"系统特色",
    case02_6:"灵活、 动态适应的科研决策管理指标体系",
    case02_7:"围绕经营管理（科研投入）、 科技创新（科研产出）、管理支撑（科研活动）三部分构成的多级递阶指标体系，涵盖了科研管理生命周期的各个环节。 在科研管理过程中设置指标稽核点，可以快速掌握科研过程的现状和潜在的问题。",
    case02_8:"柔性化、 交互式的即席报表",
    case02_9:"层级式／列表式 报表格式排序和筛选;显示／隐藏列·行首／列首冻结;多种图形样式 （柱状图、 曲线图、 饼图、 雷达图…）;报表导出和发布(Excel、 PDF、 XML …)",
    case02_10:"3D动态管理驾驶舱",
    case02_11:"与指标体系无缝对接;多种仪表布局方式（左右结构、上下结构、混合结构）;指标间级联和钻取;丰富的图表组件库;基千HTMLS的跨平台能力",
    case02_12:"可视化数据整合系统",
    case02_13:"可视化数据整合系统;直观、 拖拽式配置界面;丰富的预制组件库;强大的转换函数功能;灵活的执行计划",
    case02_14:"灵活、动态适应的科研决策管理指标体系",
    case02_15:"柔性化、交互式的即时报表",
    case03_1:"油品检验检测LIMS系统",
    case03_2:"实现销售企业是现实管理模式的变革",
    case03_3:"构建智慧化的质量监管新模式",
    case03_4:"建立成品油气质量预警体系",
    case03_5:"系统构架",
    case03_6:"1、实验室信息管理系  2、质量监督管理系统 3、质量预警管理系统  4、成品油质量数据库（见技术架构图）",
    case03_7:"技术路线",
    case03_8:"1、统一软件平台。2、大集中式硬件配置模式。 3、顶层设计，统一业务流程，统一编码体系，统一建设。4、自主研发仪器连接软件，实现仪器数据自动采集。 5、多系统互联，信息融合共享。6、按照国家二级等保要求设计安全保护策略。",
    case03_9:"应用效果",
    case04_1:"油品销售管理分析系统（S• ERP)",
    case04_2:"开发背景",
    case04_3:"目的",
    case04_4:"国力科技油品销售管理分析系统主要为石油销售行业开发，目前国内的加油站销售、管理、财务系统都为单独部署，各系统无法接入，进销存数据分散在各个加油站，与业务、财务系统存在相互之间无法数据访问。大噩的进销存单据只能在财务系统中手工制证，暴露出工作呈大、 易出错、工作不及时等问题需要解决。并且在日常业务工作中，采购、退货、库存盘点、资金核对等业务也存在数据分散和不完整、采购流程不完整等问题，造成很多工作只能手工管理。",
    case04_5:"国力科技油品销售管理分析系统包含三大部分：加油站销售管理系统，综合业务分析管理系统，财务系统。其中综合业务分析系统包含：综合业务管理、 数据分析、 数据采集、 财务数据同步四大模块。系统完成对公司进销存及日常业务数据的数据集成，并自动将数据同步到财务系统进行制证、 资金核对等业务，基本实现对加油站日常应用的智能化、 自动化。其中的经营分析模块更是根据业务需要强化业务的主题性分析。",
    case04_6:"系统构架",
    case04_7:"经营分析方向",
    case04_8:"综合分析",
    case04_9:"收入分析、客单价分析、效率分析、分时段分析、卡销比分析、客户消费分析、效率分析、不活跃客户分析、售时段分析、罐存预警分析、促销赠品分析、积分兑换分析、统计分析等。",
    case04_10:"库存管理类分析",
    case04_11:"自动补货安全库存建议值表、 即将缺货商品报表等。",
    case04_12:"销售类分析",
    case04_13:"销售商品排行榜、畅销品清单、滞销品清单、润滑油统计分析表、负毛利报表、油品综合贡献度分析、顾客清况、支付方式、库存周转率等。",
    case04_14:"商品类分析",
    case04_15:"商品综合贡献度分析、价格带分析、便利店诊断等。",
    case04_16:"综合分析、库存管理类分析",
    case04_17:"销售类分析",
    case04_18:"商品类分析",
    case05_1:"综合工作平台",
    case05_2:"多子系统/模块、多工作协同",
    case05_3:"规范化、一体化、可视化、移动化",
    case05_4:"提高各业务协同工作的效率",
    case05_5:"综合工作平台：整合了综合计划、稽查系统、重点工作、综合服务、油样检验、人事管理、工作任务等多个业务子系统，并结合移动办公，完成多系统向一个平台的信息化工作的转型，同时通过移动办公，提高各业务协同工作的效率。",
    case05_6:"综合计划",
    case05_7:"包括综合计划、工作执行和考核评价两大模块，从制定综合计划为开始、对综合计划过程反馈，最后对综合计划和工作执行各指标完成情况进行考核评价为结束的闭环管理。",
    case05_8:"工作任务",
    case05_9:"工作任务是帮助管理层跟进每一项重要的任务，任务的执行人可以及时向管理层汇报任务进展与总结，保证每一项工作任务都有结果，提升团队协同的效率。",
    case05_10:"综合服务",
    case05_11:"规范本公司公务用车、公房使用和会议室使用，加强公务车、公房和会议室的管理和监督。",
    case05_12:"油样检验",
    case05_13:"改变目前加油站、物流仓储中心、调运处油样检验申请工作方式，完成油样检验申请从申请到审批全流程的电子化。",
    case05_14:"人事管理",
    case05_15:"改变目前员工请假和办理、使用因私出国证件管理的工作方式，完成这些工作从申请到审批全流程的电子化。",
    case05_16:"稽查管理",
    case05_17:"制定稽查工作计划并进行稽查工作管理，及早发现经营管理中的薄弱环节和关键风险点，采取有效措施积极整改。",
    case06_1:"绩效考核系统",
    case06_2:"快速变化的市场环境、日益激烈的竞争压力，迫使企业越来越关注绩效，'有衡量才能有管理'的理念越来越深入人心。在未来的竞争中，只有那些专注建立核心竞争力、不断改进和提高绩效、锻造持久竞争优势的企业才能在市场竞争中获得有利位置。国力科技从建立和完善企业绩效考核与工作改进机制的角度，遵循“工作实绩优先，过程和结果相结合，责权统一，公平分配，按劳取酬”的原则，研发了一套融合了主观定性考评和客观定量考核的绩效考核系统，为现代企业人力资源管理提供了最佳解决方案。",
    case06_3:"系统特点",
    case06_4:"考核体系动态灵活，适应管理差异",
    case06_5:"◇构建灵活的绩效考核体系，可适应360度评估、岗位KPI、平衡计分卡、目标管理等考核方法  ◇分类分层的指标体系，具有良好的扩展性和适应性 ◇支持客观定量指标和主观定性指标，可定义指标关联关系、权重和分值区间 ◇自定义考核评分表模板，可适应不同岗位、不同业务领域、不同考核方式",
    case06_6:"主客观评价相互补充",
    case06_7:"◇灵活设置考核主体，适应项目制和矩阵式考核环境 ◇支持考核主体的动态权重 ◇独特的薪点贡献率模型，动态发布岗位工作目标量化基准值 ◇支持客观考核目标量化算法，实现客观考评的准确性",
    case06_8:"系统功能",
    case06_9:"绩效分析多维展现",
    case06_10:"◇员工视图（个人绩效档案、绩效能力分析等） ◇管理视图（绩效排名、考核进度查询等） ◇丰富的分析方法（对比分析、趋势分析、占比分析等）",
    case06_11:"系统架构",
    case06_12:"◇考核指标体系管理实现了绩效考核指标的类别化、层级化、属性化、量性化管理 ◇员工绩效评分以绩效考核指标体系为核心，依托规范化的绩效流程平台和丰富的考核模型，",
    case06_13:"实现多维度评价的主观定性评价体系",
    case06_14:"◇技术岗位工作任务量化考核利用核心业务系统中对工作任务量化分解、分配和核算的数据，考核员工的贡献度，实现科学、准确的客观定量评价体系  ◇绩效结果分析通过多种分析方法和展现模型，实现直观的、立体的、全方位的分析功能，便于员工和管理者对绩效结果进行多维度分析  ◇外部系统接口采用ESB（企业服务总线）及自动化数据提取技术，实现用于绩效考核的基础数据的自动加载，为绩效考核的客观性、准确性提供数据支撑",
    case07_1:"国际漫游用户链路加速平台",
    case07_2:"提升国漫用户的出镜导航用户体验",
    case07_3:"增强国际漫游业务的用户粘度",
    case07_4:"提供成熟的应用软件实现核心功能",
    case07_5:"本系统将支持高并发连接，支持选择不同VPN协议，支持境内与境外多链路切换，支持CDN的用户流量与GTP隧道信息进行关联，支持不同的负载均衡策略，支持SNI黑名单，支持SNI白名单，支持SNI黑白名单的动态切换，支持用户流量审计，支持热切换，支持后端转发服务存活检测，支持后端服务器存活检测。",
    case07_6:"基于协程技术实现了高并发的Socket转发模块；基于多层转发技术增加了转发系统的灵活性及可靠性；基于透明加密技术解决了防火墙深度检测问题。针对某个特定IP的范文流量可实现实时管控，阻断该恶意流量的通信。基于高性能IP地址匹配引擎对黑名单中的IP进行识别，并通过流量管控模块对相应的流量进行管控。CDN加速系统实现方案如下图所示。",
    case08_1:"企业的综合工作平台企业微信移动应用",
    case08_2:"综合计划平台包括综合计划、工作执行和考核评价",
    case08_3:"移动办公摆脱办公环境的制约",
    case08_4:"综合计划平台包括综合计划、工作执行和考核评价三大模块，即综合计划与考核系统包含了以制定综合计划为开始、对综合计划过程反馈，最后对综合计划和工作执行各指标完成情况进行考核评价为结束的闭环管理。",
    case08_5:"移动办公摆脱办公环境的制约，各平台工作相关人员得以更加及时高效地处理正常工作或突发情况",
    case09_1:"面向企业故障运维处理平台",
    case09_2:"快速响应、集中管控",
    case09_3:"自动备案、AI机器人互动",
    case09_4:"无纸办公、在线答疑",
    case09_5:"目前的软硬件维护方式还滞留在问题全靠吼、培训全靠跑、试卷还用纸、学习全靠讲的传统方式。",
    case09_6:"启用智能的共享运维400管理平台，将各类运维工作流程整合，化繁为整，既统一了各厂家的售后维护方式，又加强了维护流程的管理，同时增加了客服AI机器人、手机端在线考试、手机端问题库等多种辅助功能。",
    case09_7:"搭建快速响应、集中管控、自动备案、AI机器人互动、无纸办公、在线答疑的共享运维平台。",
    case09_8:"1、具备数据共享、AI语音智能的运维平台，实现人机结合、提高服务质量、运维数据共享的目的。实现运维工作的自动化、智能化。 ",
    case09_9:"2、文字、语音智能查询，AI机器人互动消息。",
    case09_10:"3、问题查看、分析。",
    case09_11:"4、共享运维400平台知识库建设",
    case09_12:"平台主要有故障上报、自助搜索、统计分析、学习园地等四大功能模块。 按平台划分又分为数据管理系统和企业微信两部分。",
    case10_1:"工程建设管理系统",
    case10_2:"统一平台，统一展现，多个应用",
    case10_3:"合理的设置流程检查点，驱动项目管理过程",
    case10_4:"强大的统计报表和数据分析功能",
    case10_5:"大型工程建设周期长，涉及单位广，建设项目多，项目种类杂，工程管理机构和人员缺乏有效的宏观管理手段和工具。国力科技自主研发的工程建设管理系统，以创新的“多级监管”模式，实现工程建设的规范化、系统化、科学化管理。规范了项目计划执行、资金支付和工程档案的管理，确保工程档案齐全、完备，为工程结算、审核、审计和可持续发展提供全面、完整和科学的依据。",
    case10_6:"系统目标",
    case10_7:"建立完善的项目管理机制，规范项目管理业务流程，整合项目资源，规避项目实施风险，实现项目预期目标，从整体上提高工程项目管理水平。建立适合政府投资的工程项目资金支付程序，充分行使项目管理机构的职责，加强财政支出管理，完善监督制约机制，保证项目建设成本的真实、准确。建立一套符合工程实施的档案管理系统，实现对纸质、电子档案信息的集中统一管理、利用、数据规范、安全保护和数据支持。",
    case10_8:"系统架构",
    case10_9:"统一平台，统一展现，多个应用基础架构服务、业务应用服务和信息展现服务独立运行，互不影响 基于面向服务的基础架构平台搭建自动的流程服务— 用于管控各种业务流程，从线性、结构化流程，到协作性、特别流程或者两种流程的混合体",
    case10_10:"高效的工作协同服务—为项目团队创建一个虚拟的工作空间，把相关人员、流程和内容集合在一起。强大的统计查询服务— 提供灵活和高效的统计查询工具。直观的报表服务— 提供了易用且高效率的报表快速设计方案。灵活的用户权限服务— 根据项目组织架构和业务流程体系设计基。于角色的权限授权和认证机制",
    case10_11:"◇档案工作前置思路，项目文档的收集贯穿项目过程  ◇档案全息数据库，支持结构化数据/非结构化数据/半结构化数据的管理和检索  ◇完整的档案生命周期管理  ◇符合国家档案管理标准，支持传统/简易归档方式强大的统计报表和数据分析功能  ◇类Excel数据报表功能，支持排序、筛选、隐藏、冻结等功能  ◇图形化的OLAP分析，提供多维多角度的分析图表  ◇独创的项目管理KPI的项目管理驾驶舱，项目进度、成本等宏观数据一目了然。",
    case10_12:"系统特色-基于动态表单的项目模板，适应不同类型项目的管理模式",
    case10_13:"◇支持子项目分解/任务分解/工序分解等多种项目模式   ◇灵活、易用的自定义项目工序模板，适应不同项目工序要",
    case10_14:"系统特色-合理的设置流程检查点，驱动项目管理过程",
    case10_15:"◇完整的项目管理流程，涵盖项目储备、项目立项、项目实施、项目验收的全过程闭环管理。  ◇灵活的业务审批流程，监控每个执行环节  ◇以合同支付为核心的记录、警示和追溯体系",
    case11_1:"通信工程施工现场管理系统",
    case11_2:"基于WBS的施工管理任务分解",
    case11_3:"通信工程全什么周期精细化'双闭环'管理体系",
    case11_4:"电信运营商的基础设施建设是一项投资规模大、建设周期短、参建单位多、技术质量要求高、管理复杂的工程。在整个建设期间涉及到施工计划、施工进度、施工质量、施工安全、设备安装以及资料管理等众多内容。但由于野外施工点分散，交通不便等客观条件，造成现场管理难度大，成本高，管理模式过于粗放，以至于施工单位是否按照实施工艺要求施工无据可循；监理单位是否巡检到位无法监控，监理责任无从考证；工程进度无法真实掌握；对于质量整改结果无法有效跟踪。",
    case11_5:"针对上述问题，国力科技自主研发的通信工程施工现场管理系统充分融合了通信工程的特点，围绕工程项目的进度、质量和文档资料，实现工程实施阶段现场管理的精细化管控，对项目实行动态、定量和系统化的管理和控制，使业主、监理、施工和供货各方及时了解工程建设的信息，提升项目进度/质量的管控效率。“精益求精，细大不捐”—通信工程的精细化管理实现建设效率和投资效益双提升",
    case11_6:"功能介绍-基于WBS的施工管理任务分解",
    case11_7:"◇项目分解细至工序和施工区间，管理责任落实到人  ◇预置工序模板，实现分专业支撑，涵盖无线和传输两大类专业， ◇符合电信运营商分层管理体制，支持各级工程建设管理机构对程建设过程中相关利益主体的管控责任、管控要点和管控方式。",
    case11_8:"系统特色-通信工程全生命周期精细化“双闭环”管理体系",
    case11_9:"◇基于全生命周期管理思想，实现通信工程建设从规划、计划、立项、设计、采购、施工、初验交维、试运行、终验、后评估的“大闭环”管理  ◇基于“二八法则”，设置工程建设关键环节/重点管控场景，明确每个重点管控环节的具体管控要求，实现流程关键环节/重点管控场景的“小闭环”管理   ◇完整的、灵活的内部管控流程，包括开工申报、验收报批、工序报验、工程周报、项目变更等。",
    case11_10:"系统特色-创新的手机巡监报验模式，将管理触角延伸至施工现场",
    case11_11:"◇在重点管控环节（如隐蔽工程、安全防护等），设置检查控制点，支持拍照上传功能  ◇“GeoTagging”技术使照片附带地理信息标签和时间戳，杜绝弄虚作假的现象，提高监管效率  ◇借助无线网络和手持终端的便利性，实现对外勤人员的调度与联系，降低外勤成本  ◇通过轨迹重现功能，掌握每次巡检路径，为外勤人员的绩效考核提供依据  ◇多维度，多视觉  ◇项目信息实时更新",
    case12_1:"石油销售业务稽核管理系统",
    case12_2:"灵活可扩展",
    case12_3:"精细化'双闭环'管理体系",
    case12_4:"丰富的数据分析功能辅助决策",
    case12_5:"随着石油销售企业的业务发展，健全内部控制细则，执行内控标准成为其工作重中之重。 内控对收入的确认以及企业经营的规范性和合理性有严格的条款规定。加强业务稽核，保障收入完整，是企业完善治理结构的内在需求。 为适应内控需要，减少潜在风险，稽核 工作将成为企业日常管理工作中重要的—个环节。",
    case12_6:"国力科技研发的石油企业销售业务稽核管理系统，从石油销售行业角度出发，设计思路借鉴千石油销售企业基础业务运作模式，涉足成品油、非油品、润滑油、小产品和物流等业务领域， 通过合理化系统的构建，将多渠道的日常业务关系纵向的串联起来，目的是使整个石油销售产业链所存在的经营性风险问题清晰可见，从而采取针对性的审计手段，降低或改善石油销售企业内部经营隐患，增强监管力度，避免资源浪费。",
    case12_7:"系统目标",
    case12_8:"提高石油销售企业稽核部门的风险监控和预警能力，及时发现存在的风险并有效的防范，对已出现的违规问题进行纠改，同时改进稽核方法，提高稽核效率，规 范稽核操作，力求实现稽核全程系统化，使稽核工作能够逐步向规范的、 科学的和有序的方向发展，从而达到全面提高稽核效率和质量。",
    case12_9:"产品功能",
    case12_10:"高效稳定",
    case12_11:"将自主研发的ETL工具融入到整套系统当中，通过自主调度、差错处理、质虽检查、 性能监控、 多渠道支持、 日志分析等多元素技术方案为稽核管理系统保驾护航。",
    case12_12:"系统特点-灵活可扩展",
    case12_13:"·动态的全业务稽核体系，实现稽核体系中稽核项目的分类、 分级管理。 可根据不同时间段的稽核目标和稽核内容，自定义稽核工作单模板，灵活调整稽核项目。 ·适应稽核工作的组织机构管理系统，可实现组织机构的动态管理，层级管理。 特别适合石油销售企业销售终端的多样性和多变性需求。 ·基千稽核项目和稽核对象的立体赋权结构，实现对岗位角色的动态赋权。",
    case12_14:"系统特点-精细化闭环管理模式",
    case12_15:"·适应业务现状和发展的稽核工作管理系统，支持各种类型的稽核工作流程，实现“计划—检查—整改—复核” 的闭环管理模式。 ·基千CASE的事件跟踪体系，便千间题追溯。",
    case12_16:"系统特点-丰富的数据分析功辅助决策",
    case12_17:"·系统提供丰富的数据分析功能，将一段期间内的间题进行分类筛选工作，把高发期时间段所 涉及到的安全隐患毫无保留的展现给管理者，管理者可 通过分析结果针对性的对所面临的问题进行相应的调整措施。·稽核工作考评系统，实现领导对稽核工作的全面了解和掌握，对稽核人的工作成效，对稽核对象的稽核结果进行查询和统计。",
    case13_1:"加油站经营监控管理系统",
    case13_2:"整理各个业务系统的加油站日报表",
    case13_3:"结合加油站预算数据",
    case13_4:"根据定义的加油站利润日监控模型",
    case13_5:"整理各个业务系统的加油站日报表（包括HOS、FMIS 、IC卡、电子券查询系统等），结合加油站预算数据（主要是费用预算或者上月费用数据），根据定义的加油站利润日监控模型，通过销量、收入、成本、折扣、费用等各项数据计算加油站每日毛利和利润，形成利润日监控报表。加油站利润日监控报表分为两部分。第一部分，基于油品销售收入，计算成本、优惠折扣、费用分摊等，形成油品利润日监控报表。第二部分在油品利润日监控的基础上，加上财务报表提供的非油收入、成本、毛利等，形成加油站利润日监控报表。",
    case14_1:"阿米巴经营应用软件",
    case14_2:"经营核算",
    case14_3:"绩效核算",
    case14_4:"经营分析",
    case14_5:"通过油站阿米巴主要经营指标测算各岗位员工每日绩效。油站站长可根据岗位/人员贡献度，进行绩效权重调整。叠加每月月度评价系数后，可以准确核算出每个员工当月绩效工资。聚焦油站基层经营管理质效层面，开展加油站销量分析、加油站非油收入分析、加油站费用分析、加油站利润分析、油站工作效率分析以及对标站比对分析等各类专题分析功能开发。",
    case14_6:"通过对油站每日经营数据核算，实现油站每日经营绩效核算。通过数据分析全面评价油站运营质效，并引导优化改进。",
    case14_7:"加油站经营管理需要每日掌握油站当天经营指标概况，根据加油站经营业务数据进行销售质效评估，同时为人员绩效核算提供依据。",
    case14_8:"根据加油站每日经营核算结果，对比油站预算及经营指标达成情况，导入油站岗位绩效算法。每日核算出油站及员工绩效情况，达到良性竞争与直接激励的效果。",
    case14_9:"以“阿米巴”经营分析工具，快速找到与对标站经营数据中的待优化部分，差缺补漏。激发各油站自主能动独立运营管理，引导油站利润与效益提升 ",
    case15_1:"移动核心网业务联动系统",
    case15_2:"程序监控模块",
    case15_3:"流量回溯模块",
    case15_4:"数据报表模块等",
    case15_5:"本系统将支持对用户通过App提交的授权信息处理，支持对域名规则进行维护，支持域名规则的动态切换，支持特定方向批量开通授权，支持对用户的授权有效期进行监控，支持对失效授权的自动续期，支持授权的自动缓存提交，支持提交失败授权的重复提交，支持到访用户信息列表的导出，支持到访用户信息列表的推送，支持特定用户关联信息的分布式检索。国力业务联动系统功能模块图如下图所示。",
    case15_6:"业务联动协同方面可以实现对网元和黑白名单、DNS规则、以及用户权限信息进行统一配置管理、然后实时监测、导流国际漫游用户的境外地图访问行为，对存在的风险进行及时预警。最后基于各个维度的分析数据进行态势呈现，以地图、图表等方式进行呈现。同时与运营商数据漫游策略控制系统、业务支撑系统对接，提供平台的分析结果数据，同步实时接收用户流量包信息数据和策略控制系统下发的管控策略。",
    case16_1:"移动网络特定用户流量定位系统",
    case16_2:"高性能捕包模块",
    case16_3:"网络行为分析模块",
    case16_4:"业务数据处理模块等",
    case16_5:"本系统支持对分光中GTP报文进行高速捕获，支持GTPu对DNS、SSL、QUIC、HTTP数据包的深度解析，支持GTPc会话缓存功能，支持GTPu的TEID缓存功能，支持GTPc与GTPu隧道的实时关联功能，支持GTPc会话信息查询功能，支持旁路数据包注入功能，支持针对Android系统的无感知流量牵引功能，以及特定应用的域名黑名单、特定应用的域名白名单等功能。国力特定流量引导系统功能模块图如下图所示。",
    case16_6:"系统基于零拷贝、无锁环形队列等独有技术，实现高性能报文捕获引擎。在处理大流量GTP协议时，需要定制并行化的GTP协议栈，一方面需要实现高性能的协议栈；另一方面需要支持GTP会话保持功能，实现GTPc和GTPu隧道的信息关联。DNS劫持报文应符合隧道协议规范，基于数据流上行和下行信息统计，且应在真正的响应报文到达之前注入到链路中，对协议解析及报文封装速度要求高，实现方案如下图所示。",
    case17_1:"加油站单站核算与分析平台",
    case17_2:"建立共享数据库",
    case17_3:"汇集现有的财务",
    case17_4:"零管等业务系统的数据",
    case17_5:"背景",
    case17_6:"费用成本难以分摊核算至具体加油站。不同类型的油站无统一评价标准。油站经营管理日报为半手工化处理。油站周期经营数据难以叠加、对比分析。",
    case17_7:"内容",
    case17_8:"建立共享数据库，汇集现有的财务、零管等业务系统的数据。建立加油站和分公司的指标体系，完成对加油站各项经营指标的核算，对加油站和分公司的实际经营状况作出全面和客观的评价。",
    case17_9:"系统架构-实现目标",
    case17_10:"业务部门",
    case17_11:"·对财务账面数据进行单站还原，规范单站核算。 ·油气非业务的经营数据精准监控，营销活动决策支持。 ·实现对加油站单站创效能力的综合反映和经营策略优化。",
    case17_12:"分公司管理者",
    case17_13:"·打造加油站预算管理体系、实现加油站对标分析  ·从业绩到指标改变工作管理思路，提高工作效率。",
    case17_14:"油站经营者",
    case17_15:"·及时了解经营指标构成情况和预算指标完成情况，并进行经营指标预警，发现问题或短板。",
    case18_1:"技术支持与保障",
    case18_2:"随着中国石油销售公司各统建系统和自建系统的应用，信息系统已成为经营和管理不可缺少的工具。 为了满足日常信息系统运行维护的工作需求，处理好技术服务与使用的关系，做好系统建设与系统维护工作，保证信息系统安全、 平稳、 可靠运行，需要有技术为整体业务系统安全、稳定运行提供全方位的保障服务支持。我公司作为系统运维单位，目前已在4个省开展系统运维工作，为企业的信息工作开展起到了强有力的支持作用。",
    case18_3:"服务流程",
    case18_4:"培训流程",
    itccase01_1:"数据中心支撑环境搭建",
    itccase01_2:"数据中心机房作为信息网络的承载环境，是支撑各类信息数据计算与处理重要基础支撑平台。信息系统可靠运行要依靠数据中心机房严格的环境条件（机房温度、湿度、洁净度及其控制精度）和工作条件（防静电性、屏蔽性、防火性等）。",
    itccase01_3:"国力科技数据中心支撑环境综合解决方案包含以下子系统的建设：（1）机房立面建设工程；(2）配电照明工程；(3）空调新风工程；(4）机房环境监控系统工程；(5）KVM集中管理系统；(6）统一监控展示单元工程；(7）防雷接地工程；(8）机房气体消防工程；(9）机柜与综合布线工程；（10）场地加固工程；（11）低压配电与不间断电源工程等。",
    itccase02_1:"综合布线服务",
    itccase02_2:"在过往的IDC综合服务交付过程中，国力科技积累了数据中心综合布线设计、敷设、理线、跳接、维护等全方位服务经验。能够为客户提供兼具先进性、兼容性、开放性、灵活性、可靠性和经济性的综合布线服务方案。",
    itccase03_1:"动环及视频监控系统",
    itccase03_2:"国力科技携手行业领先的数据中心环境监控设备厂商为客户提供一体化数据中心动力及环境监控管理系统解决方案。",
    itccase03_3:"助力客户对数据中心机房的动力设备及环境情况进行实时的监控管理：对设备运行信息实时获取，第一时间接收各类告警信号，从而实现安全隐患风险有效规避。",
    itccase03_4:"动环监控中心主要功能包括：",
    itccase03_5:"1)接收前端采集程序监测到的设备和环境的有关参数（运行状态、工作参数、报警信息等），直观动态实时地显示给用户；",
    itccase03_6:"2)采集前端设备报警事件，在界面上以警示的方式告知用户，同时将报警的具体内容以语音、电话、手机、短信等方式发布给有关管理人员；",
    itccase03_7:"3)对采集的数据和发生的报警事件提供统计查询、分析、报表等管理辅助应用。",
    itccase03_8:"4)发送控制命令给前端采集程序，控制有关设备的启停或相关操作；",
    itccase03_9:"5)提供远程监控管理功能。",
    itccase03_10:"动环监控中心详细功能还包括：",
    itccase04_1:"设备巡检与应急处理",
    itccase04_2:"综合我公司多年来在数据中心技术服务项目中积累的经验，我公司可以为客户提供定向设备巡检或固定周期IDC全面巡检服务。协助客户主动发现并解决问题，为用户信息设备运行及维护情况提出改进建议，并提供预防性报告，保证用户系统持续、稳定的运行。",
    itccase04_3:"国力科技驻场工程师团队对数据中心常见各类设备、系统均有丰富的维护服务经验，非常了解数据中心中所有设备系统的通常使用年限和常用备件的更替频率；因此可根据用户设备现状与实际应用情况，设计针对具体客户的备品备件库，以备运维服务中的不时之需。",
    itccase04_4:"国力科技能提供数据中心的应急响应服务，当客户进行网络割接、设备扩容升级、新系统投产、数据迁移等一系列项目活动遇到无法预见的事故及故障等问题，能够在现场第一时间对应技术资源赴现场协助客户进行排查与应急处理。",
    itccase05_1:"ICT设备安装与交付",
    itccase05_2:"国力科技与国内外众多通信运营企业、大型IT设备供应商及云厂商建立了深度合作关系，具备覆盖ICT全方位的技术支持与服务能力，为通信行业企业及其大型客户提供数据中心部署建设、综合布线、智能化建筑、动力与监控系统搭建、POP点建设等一系列解决方案。",
    itccase05_3:"目前服务已涵盖的主要国家及地区有：",
    itccase05_4:"中国、中国香港、新加坡、日本、韩国、澳大利亚、新西兰、菲律宾、印度尼西亚、越南、马来西亚、巴基斯坦、泰国等；",
    itccase05_5:"美国、加拿大、墨西哥等；",
    itccase05_6:"德国、英国、法国、俄罗斯等；",
    itccase05_7:"尼日尼亚、南非、埃及等。",
    itccase06_1:"全球物流清关",
    itccase06_2:"作为一站式ICT解决方案供应商，国力科技在给国内、国际客户提供优质技术服务的同时，专注国际物流与清关资源的开拓。目前，在合作伙伴的支持下，国力科技以实现全球100多个国家及地区敏捷物流与清关服务能力。亚太地区主要国家5个工作日内运抵，为客户提供全程物流、清关及税费代缴服务。",
    itccase07_1:"7*24驻场服务",
    itccase07_2:"国力科技为客户提供全方位的ICT驻场服务解决方案。包括数据中心运行保障服务、面向最终用户的业务应用服务、基础设施服务和信息资源管理服务各个类别的多项服务，覆盖从IT基础设施、业务应用系统以及信息安全的各层次、各方面的IT运行维护和保障。",
    anlitext01:"中国运营商M国际漫游链路加速项目",
    anlitext02:"中国运营商M亚太区POP点建设项目",
    anlitext03:"中国运营商M日本VPN扩容项目",
    anlitext04:"中国运营商U欧洲POP点扩容项目",
    anlitext05:"阿里亚洲CDN项目",
    anlitext06:"中兴欧洲CDN项目",
    anlitext07:"中国运营商M美国和新加坡DCIM项目",
    anlitext08:"中国运营商C亚太 IDC综合布线",
    anlitext09:"中国运营商M香港机房布线运维项目",
    anlitext10:"中国某石油集团华南数据中心设备巡检项目",
    anlitext11:"某跨国石油合资公司数据中心运维项目",
  },
  user: {
      login:'登录', 
      register:'注册',
      loginUsername:'请输入邮箱/手机号',
  }
}