<template>
  <div>
    <div style="position: relative;">
      <el-menu
        style="margin-right:100px;"
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="rgba(255,0,0,0)"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-menu-item
          class="itemNav"
          id="home"
          index="0"
          @click.native="goPath(0)"
          >{{ $t('Nav.home') }}</el-menu-item
        >
        <el-submenu
          class="itemNav"
          id="aboutus"
          index="2"
          @click.native="goPath(2)"
        >
          <template slot="title"> {{ $t('Nav.abus') }}</template>
          <el-menu-item index="2-1" @click.native="goPath('2-1')">
            {{ $t('Nav.qiyegaishu') }}
          </el-menu-item>
          <el-menu-item index="2-2" @click.native="goPath('2-2')">
            {{ $t('Nav.qiyeyuanjing') }}</el-menu-item
          >
          <el-menu-item index="2-3" @click.native="goPath('2-3')">{{
            $t('Nav.zhuanyezizhi')
          }}</el-menu-item>
          <el-menu-item index="2-4" @click.native="goPath('2-4')">
            {{ $t('Nav.qiyewenhua') }}</el-menu-item
          >
        </el-submenu>
        <el-submenu
          index="3"
          class="itemNav"
          id="solutions"
          @click.native="goPath(3)"
        >
          <template slot="title"> {{ $t('Nav.cpfa') }}</template>
          <el-menu-item index="3-1" @click.native="goPath('3-1')">
            {{ $t('Nav.qiyezonghehuaguanli') }}</el-menu-item
          >
          <el-menu-item index="3-2" @click.native="goPath('3-2')">
            {{ $t('Nav.nengyuanshihuaguanli') }}</el-menu-item
          >
          <el-menu-item index="3-3" @click.native="goPath('3-3')">
            {{ $t('Nav.guojiyunying') }}</el-menu-item
          >
          <el-menu-item index="3-4" @click.native="goPath('3-4')">
            {{ $t('Nav.dashuju') }}</el-menu-item
          >
          <!-- <el-menu-item index="3-5" @click.native="goPath('3-5')">
            {{ $t('Nav.xitongjishuyuzhichi') }}</el-menu-item
          > -->
        </el-submenu>
        <el-submenu
          index="4"
          class="itemNav"
          id="itc"
          @click.native="goPath(4)"
        >
          <template slot="title"> {{ $t('Nav.itc') }}</template>
          <el-menu-item index="4-1" @click.native="goPath('4-1')">
            {{ $t('Nav.quanfangweiitc') }}
          </el-menu-item>
          <el-menu-item index="4-2" @click.native="goPath('4-2')">
            {{ $t('Nav.quanqiuopo') }}
          </el-menu-item>
          <el-menu-item index="4-3" @click.native="goPath('4-3')">
            {{ $t('Nav.anlifenxiang') }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item
          style="display:none"
          index="5"
          class="itemNav"
          id="news"
          @click.native="goPath(5)"
          >{{ $t('Nav.news') }}</el-menu-item
        >
        <el-submenu
          index="6"
          class="itemNav"
          id="joinus"
          @click.native="goPath(6)"
        >
          <template slot="title"> {{ $t('Nav.joinus') }}</template>
          <el-menu-item index="6-1" @click.native="goPath('6-1')">
            {{ $t('Nav.zhaopin') }}
          </el-menu-item>
          <el-menu-item index="6-2" @click.native="goPath('6-2')">
            {{ $t('Nav.peixunyufazhan') }}</el-menu-item
          >
          <el-menu-item index="6-3" @click.native="goPath('6-3')">
            {{ $t('Nav.shenghuoyufuli') }}</el-menu-item
          >
        </el-submenu>
      </el-menu>
      <el-button
        style="position: absolute; top:10px; right:0"
        @click="changeLanguage()"
        circle
        >{{ $t('language.name') }}</el-button
      >
    </div>
  </div>
</template>

<script>
import router from '../../router'
export default {
  name: 'mainNav',
  components: {},
  data() {
    return {
      activeIndex: '0',
      showMenuItem: false,
    }
  },
  mounted() {
    this.$bus.$on('getCurNav', function(row) {
      // console.log(row)
      document.getElementById('home').classList.remove('curNav')
      document.getElementById('aboutus').classList.remove('curNav')
      document.getElementById('solutions').classList.remove('curNav')
      document.getElementById('itc').classList.remove('curNav')
      document.getElementById('news').classList.remove('curNav')
      document.getElementById('joinus').classList.remove('curNav')
      document.getElementById(row).classList.add('curNav')
    })
    this.goPath(localStorage.getItem('activeIndex'))
  },
  destroyed() {
    console.log('页面销毁了')

    // localStorage.setItem('activeIndex', 22)
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale == 'zh'
        ? (this.$i18n.locale = 'en')
        : (this.$i18n.locale = 'zh') //设置中英文模式
      localStorage.setItem('languageSet', this.$i18n.locale) //将用户设置存储到localStorage以便用户下次打开时使用此设置
      this.$bus.$emit('changeLanguage', this.$i18n.locale)
    },

    goPath(num) {
      console.log(num)
      document.getElementById('home').classList.remove('curNav')
      document.getElementById('aboutus').classList.remove('curNav')
      document.getElementById('solutions').classList.remove('curNav')
      document.getElementById('itc').classList.remove('curNav')
      document.getElementById('news').classList.remove('curNav')
      document.getElementById('joinus').classList.remove('curNav')

      localStorage.setItem('activeIndex', num)
      if (num == '2-1' || num == '2-2' || num == '2-3' || num == '2-4') {
        document.getElementById('aboutus').classList.add('curNav')
      } else if (
        num == '3-1' ||
        num == '3-2' ||
        num == '3-3' ||
        num == '3-4' ||
        num == '3-5'
      ) {
        document.getElementById('solutions').classList.add('curNav')
      } else if (num == '4-1' || num == '4-2' || num == '4-3') {
        document.getElementById('itc').classList.add('curNav')
      } else if (num == '6-1' || num == '6-2' || num == '6-3') {
        document.getElementById('joinus').classList.add('curNav')
      }
      let arr = Object.values(document.getElementsByClassName('el-menu-item'))
      let arr2 = Object.values(document.getElementsByClassName('el-submenu'))

      if (arr != '') {
        arr.map(function(v) {
          v.classList.remove('is-active')
        })
        arr2.map(function(v) {
          v.classList.remove('is-active')
        })

        if (num == 2 || num == 3 || num == 4 || num == 6) {
          arr2.map(function(v, k) {
            if (num == 2) {
              router.push('/components/web/aboutus/index')
              if (k == 0) {
                v.style.background = '#475AFF'
              } else {
                v.style.background = 'rgb(255, 0, 0, 0)'
              }
            } else if (num == 3) {
              router.push('/components/web/solutions/index')
              if (k == 1) {
                v.style.background = '#475AFF'
              } else {
                v.style.background = 'rgb(255, 0, 0, 0)'
              }
            } else if (num == 4) {
              router.push('/components/web/itc/index')
              if (k == 2) {
                v.style.background = '#475AFF'
              } else {
                v.style.background = 'rgb(255, 0, 0, 0)'
              }
            } else if (num == 6) {
              router.push('/components/web/joinus/index')
              if (k == 3) {
                v.style.background = '#475AFF'
              } else {
                v.style.background = 'rgb(255, 0, 0, 0)'
              }
            }
          })
        } else {
          if (num == 0) {
            router.push('/')
          } else if (num == '2-1') {
            router.push('/components/web/aboutus/corporateVision')
          } else if (num == '4-1') {
            document.getElementById('itc').classList.add('curNav')
            router.push('/components/web/itc/case01')
          } else if (num == '5') {
            router.push('/components/web/news/index')
          } else if (num == '2-2') {
            router.push('/components/web/aboutus/qyyj')
          } else if (num == '2-3') {
            router.push('/components/web/aboutus/zyzz')
          } else if (num == '3-1') {
            router.push('/components/web/solutions/case01')
          } else if (num == '3-2') {
            router.push('/components/web/solutions/case02')
          } else if (num == '3-3') {
            router.push('/components/web/solutions/case03')
          } else if (num == '3-4') {
            router.push('/components/web/solutions/case04')
          } else if (num == '3-5') {
            router.push('/components/web/solutions/case05')
          } else if (num == '4-1') {
            router.push('/components/web/itc/case01')
          } else if (num == '4-2') {
            router.push('/components/web/itc/case02')
          } else if (num == '4-3') {
            router.push('/components/web/itc/case03')
          } else if (num == '6-1') {
            router.push('/components/web/joinus/case01')
          } else if (num == '6-2') {
            router.push('/components/web/joinus/case02')
          } else if (num == '6-3') {
            router.push('/components/web/joinus/case03')
          } else if (num == '2-4') {
            router.push('/components/web/joinus/case04')
          }
          arr2.map(function(v) {
            v.style.background = 'rgb(255, 0, 0, 0)'
          })
          arr.map(function(v) {
            v.style.background = 'rgb(255, 0, 0, 0)'
          })
        }
      }
    },
    mouseenterFun() {
      console.log('鼠标进')
    },
    mouseleaveFun() {
      console.log('鼠标开')
    },
  },
}
</script>

<style>
.el-menu--popup {
  background: #606266 !important;
}
.curNav {
  background: #475aff !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
  border-color: rgba(255, 255, 255, 0);
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  border-color: rgba(255, 255, 255, 0);
}
</style>
